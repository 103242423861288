import { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { TextField } from '@get-e/react-components';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import uuid from 'react-uuid';
import throttle from 'lodash/throttle';

import { mapPlaceToAddress } from '../pages/helpers/mapPlaceToAddress';
import InputError from '../helpers/validation/InputError';
import { Address } from '../pages/accounts/api/types';
import { loader } from '../helpers/maps/googleApi';
import useEffectAsync from '../helpers/useEffectAsync';

const autocompleteService: any = { current: null };
const googlePlaces: any = { current: null };

interface MatchedSubstrings {
    offset: number;
    length: number;
}

interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: MatchedSubstrings[];
}

interface GooglePlacesOptionValue {
    description: string;
    place_id: string;
    matched_substrings: MatchedSubstrings[];
    structured_formatting: StructuredFormatting;
}

export const defaultValueOption = {
    description: '',
    place_id: '',
    matched_substrings: [{ offset: 0, length: 0 }],
    structured_formatting: {
        main_text: '',
        secondary_text: '',
        main_text_matched_substrings: [{ offset: 0, length: 0 }],
    },
};

interface GooglePlacesAutocompleteProps {
    error: InputError | null;
    helperText: string;
    onChangeError?: (error: InputError | null) => void;
    onChangeAddress: (address: any) => void;
    label: string;
    defaultValue?: Address;
}

export const GooglePlacesAutocomplete = ({
    error = null,
    helperText,
    onChangeError,
    onChangeAddress,
    label,
    defaultValue,
}: GooglePlacesAutocompleteProps) => {
    const [value, setValue] = useState<GooglePlacesOptionValue | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<GooglePlacesOptionValue[]>([]);
    const loaded = useRef(false);

    useEffectAsync(async () => {
        if (autocompleteService.current === null) {
            loader.load().then(() => {
                loaded.current = true;

                if (!autocompleteService.current && window.google) {
                    autocompleteService.current =
                        new window.google.maps.places.AutocompleteService();

                    googlePlaces.current = new window.google.maps.places.PlacesService(
                        new google.maps.Map(document.createElement('div')),
                    );
                }
            });
        }
    }, []);

    const fetch = useMemo(
        () =>
            throttle((request: any, callback: any) => {
                autocompleteService?.current?.getPlacePredictions(request, callback);
            }, 200),
        [],
    );
    
    useEffect(() => {
        if (defaultValue) {
            defaultValueOption.description = defaultValue.full_address;
            setValue(defaultValueOption);
            onChangeAddress(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results: any) => {
            if (active) {
                let newOptions: GooglePlacesOptionValue[] = [];

                if (value) {
                    newOptions = [value];
                    googlePlaces.current.getDetails(
                        { placeId: value.place_id },
                        function (results: any) {
                            if (results) {
                                onChangeAddress(mapPlaceToAddress(results));
                            }
                        },
                    );
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            id={uuid()}
            getOptionLabel={(option: GooglePlacesOptionValue) => {
                return typeof option === 'string' ? option : option.description;
            }}
            filterOptions={option => option}
            options={options}
            autoComplete={false}
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue: GooglePlacesOptionValue | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                onChangeError?.(null);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    fullWidth
                    required
                    helperText={helperText}
                    error={error !== null}
                />
            )}
            renderOption={(props, option: GooglePlacesOptionValue) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: MatchedSubstrings) => [
                        match.offset,
                        match.offset + match.length,
                    ]),
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box
                                    component={LocationOnIcon}
                                    sx={{ color: 'text.secondary', mr: 2 }}
                                />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part: any, index: any) => (
                                    <Typography
                                        key={index}
                                        sx={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                        component={"span"}
                                    >
                                        {part.text}
                                    </Typography>
                                ))}

                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};
