import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from 'react-query';
import {
    Modal,
    PrimaryButton,
    TextField,
    SecondaryButton,
    MultipleSelect,
} from '@get-e/react-components';
import { makeStyles } from '@mui/styles';
import { CardContent, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import { PEOPLE_INVITED } from '../../../../constans/urlPaths';
import { inviteUser } from '../../../../services/users';
import { useCustomerRoles } from '../../../accounts/api/useCustomerRoles';
import {
    Severity,
    useNotificationContext,
} from '../../../../context/NotificationContext';
import { useAuth } from '../../../../context/AuthenticatedUserContext';
import isEmail from '../../../../helpers/validation/validators/isEmail';
import InputError from '../../../../helpers/validation/InputError';
import getHelperText from '../../../../helpers/validation/getHelperText';
import getInputError from '../../../../helpers/validation/getInputError';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import * as amplitudeLogs from '../../../../constans/amplitudeKeys';

const useStyles = makeStyles({
    heading: { marginBottom: '1rem' },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    buttonCancle: {
        marginLeft: '2em',
    },
    formField: {
        marginBottom: '16px',
    },
});

interface InviteModalProps {
    onClose: () => void;
    isOpen: boolean;
}

const EMAIL_LIST_SEPARATOR = ',';

const InviteModal: FunctionComponent<InviteModalProps> = ({ onClose, isOpen }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const classes = useStyles();
    const autoFocusRef = useRef<HTMLInputElement>();
    const [email, setEmail] = useState('');
    const [roleIds, setRoleIds] = useState<string[]>([]);
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const navigate = useNavigate();
    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();
    const { user } = useAuth();
    const { id = '' } = useParams();

    const customerId = (id && parseInt(id)) || (user?.customer?.id ?? -1);

    const { customerRoles, isLoading, isError } = useCustomerRoles(customerId);

    const values = useMemo(
        () =>
            new Map(
                customerRoles?.map(customerRole => [
                    customerRole.id.toString(),
                    customerRole.name,
                ]),
            ),
        [customerRoles],
    );

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    const { mutate: invitePeopleMutation, isLoading: isLoadingPeople } = useMutation(
        inviteUser,
        {
            onSuccess: () => {
                logAmplitudeEvent(amplitudeLogs.INVITE_SEND_SUCCESS);
                showNotification('Email successfully invited.', Severity.Info);
                onClose();
                navigate(PEOPLE_INVITED);
            },
            onError: (error: AxiosError<Error>) => {
                logAmplitudeEvent(amplitudeLogs.INVITE_SEND_FAILED);
                showNotification(
                    error.response?.data.message ?? t('errors.retry'),
                    Severity.Error,
                );
            },
        },
    );

    const handleSubmit = () => {
        logAmplitudeEvent(amplitudeLogs.INVITE_SEND);
        const emailsList = email
            .split(EMAIL_LIST_SEPARATOR)
            .map(element => element.trim());

        const validates = emailsList.map(email => {
            return isEmail(email, InputError.InvalidEmail);
        });

        for (const validated of validates) {
            if (!validated.isValid) {
                setEmailError(getInputError(validated));
                return;
            }
        }
        if (email !== '' && roleIds.length) {
            invitePeopleMutation({
                emails: emailsList,
                roleIds: roleIds,
            });
        }
    };

    const handleCancel = () => {
        onClose();
        logAmplitudeEvent(amplitudeLogs.INVITE_CANCEL);
    };

    const onSetIds = (ids: string[]) => {
        setRoleIds(ids);
    };

    const disabled = (isLoading && !isError) || !customerRoles?.length;
    return (
        <Modal open={isOpen} onClose={onClose} fullScreen>
            <CardContent
                sx={{
                    width: isMobile ? '100%' : '600px',
                    margin: '0 auto',
                    paddingLeft: '0',
                }}
            >
                <Typography variant="h2" component="h2" className={classes.heading}>
                    Invite people
                </Typography>
                <TextField
                    value={email}
                    onChange={event => {
                        setEmail(event.target.value);
                        setEmailError(null);
                    }}
                    label="Email"
                    type="text"
                    autoComplete="email"
                    name="email"
                    inputRef={autoFocusRef}
                    required
                    className={classes.formField}
                    error={emailError !== null}
                    helperText={
                        'Add emails one at a time or as a list. ' +
                        getHelperText(emailError, t)
                    }
                    onFocus={() => logAmplitudeEvent(amplitudeLogs.INVITE_EMAIL)}
                />
                <div className={classes.formField}>
                    <MultipleSelect
                        label="Role"
                        value={roleIds}
                        required
                        helperText="The selected role will be given to all invited people."
                        values={values}
                        onSetIds={onSetIds}
                        disabled={disabled}
                        onFocus={() => logAmplitudeEvent(amplitudeLogs.INVITE_ROLE)}
                    />
                </div>

                <div>
                    <PrimaryButton
                        onClick={handleSubmit}
                        submitsForm
                        loading={isLoadingPeople}
                        disabled={disabled}
                    >
                        Send Invite
                    </PrimaryButton>
                    <SecondaryButton
                        onClick={handleCancel}
                        className={classes.buttonCancle}
                    >
                        Cancel
                    </SecondaryButton>
                </div>
            </CardContent>
        </Modal>
    );
};

export default InviteModal;
