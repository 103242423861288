import { useEffect } from 'react';
import { DataGrid } from '@get-e/react-components';
import { useMediaQuery } from '@mui/material';
import { GridEventListener } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { useDeactivated } from '../../api/useDeactivated';
import { getColumns } from '../gridData';
import { useAuth } from '../../../../context/AuthenticatedUserContext';
import { useDataGridStyles } from '../styles/useDataGridStyles';
import { emptyAirline } from '../../../../images';
import { EDIT_PEOPLE_BASE_URL } from '../../../../constans/urlPaths';
import { LOCAL_STORAGE_KEYS } from '../../../../constans/localStorageKeys';
import * as amplitudeLogs from '../../../../constans/amplitudeKeys';
import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { mapUserToTableRow } from '../../helpers/tableMapper';

interface DeactivatedProps {
    onUpdate: (deactivated: number) => void;
    isActive: boolean;
}

const DeactivatedPanel = ({ onUpdate, isActive }: DeactivatedProps) => {
    const dataGridClasses = useDataGridStyles();
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const { id = '' } = useParams();
    const { user } = useAuth();

    const customerId = (id && parseInt(id)) || (user?.customer?.id ?? -1);

    const { deactivatedPeopleData = [], isFetching: isFetchingDeactivated } =
        useDeactivated(customerId);

    useEffect(() => {
        onUpdate?.(deactivatedPeopleData?.length ?? 0);
    }, [deactivatedPeopleData?.length, onUpdate]);

    const rows = deactivatedPeopleData.length ? mapUserToTableRow(deactivatedPeopleData) : [];

    const handleRowClick: GridEventListener<'rowClick'> = (params, event) => {
        event.preventDefault();
        window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_EDITED,
            params.row.name ?? '',
        );
        navigate(`${EDIT_PEOPLE_BASE_URL}${params.id}`);
        logAmplitudeEvent(amplitudeLogs.EDIT_USER_DEACTIVATED)
    };

    return (
        <div className={dataGridClasses.dataGrid}>
            {isActive && (
                <>
                    <DataGrid
                        className={clsx(
                            dataGridClasses.dataGrid,
                            !rows.length ? dataGridClasses.dataGridNoRows : '',
                        )}
                        rows={isFetchingDeactivated ? [] : rows}
                        columns={getColumns()}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        autoHeight
                        noRows="No result found"
                        loading={isFetchingDeactivated}
                        getRowHeight={() => (isMobile ? 'auto' : null)}
                        noResultImage={emptyAirline}
                        onRowClick={handleRowClick}
                        noRowsClassName={dataGridClasses.noResultsWrapper}
                        hideFooter={!rows.length}
                    />
                </>
            )}
        </div>
    );
};

export default DeactivatedPanel;
