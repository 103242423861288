import makeStyles from '@mui/styles/makeStyles';
import { Pagination } from '@mui/material';
import { Stack } from '@mui/system';
import { FullScreenLoader, PrimaryButton } from '@get-e/react-components';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { Booking, BookingStatus } from '../../api/types';
import {BOOKINGS, CREATE_BOOKING } from '../../../../constans/urlPaths';
import { emptyAirline } from '../../../../images';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import * as amplitudeLogs from '../../../../constans/amplitudeKeys';
import BookingCompactCard from '../bookingCard/BookingCompactCard';
import { useIsBackoffice, useIsHotelUser } from '../../../../context/AuthenticatedUserContext';
import HotelUserBookingCard from '../bookingCard/HotelUserBookingCard';

const useStyles = makeStyles({
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
    },
    emptyDiv: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
        marginBottom: '5rem',
    },
    createButton: {
        display: 'flex',
        margin: '0 auto',
    },
});

interface BookingsProps {
    res?: Booking[];
    setPage: (num: number) => void;
    countPages?: number;
    isLoadingBookings: boolean;
    isRefetchingBookings: boolean;
    updateBookingsChangeList: (id: number, status:BookingStatus, confirmationNumber: string, hotelComments: string) => void;
    removeFromUpdateList: (id: number) => void;
}

const List = ({
    res,
    setPage,
    countPages,
    isLoadingBookings,
    isRefetchingBookings,
    updateBookingsChangeList,
    removeFromUpdateList,
}: BookingsProps) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBookingClick = (id: number) => {
        navigate(`${BOOKINGS}/${id}`, {
            state: {
                id: id,
            },
        });
        logAmplitudeEvent(amplitudeLogs.BOOKING_OVERVIEW);
    };

    const isHotelUser = useIsHotelUser();
    const isBackoffice = useIsBackoffice();

    return (
        <>
            {(isLoadingBookings || isRefetchingBookings) && <FullScreenLoader />}
            {isHotelUser 
                ? res?.map((booking: Booking) => (
                    <HotelUserBookingCard
                        key={uuid()}
                        bookingId={booking.id}
                        status={booking.status}
                        bookingNumber={booking.bookingNumber ?? ""}
                        hotelComments={booking.hotelComments}
                        checkInDate={booking.checkInDateLocal}
                        checkOutDate={booking.checkOutDateLocal}
                        guests={booking.bookingGuests}
                        customerName={booking.customerName}
                        units={booking.units}
                        pendingUpdates={booking.pendingUpdates}
                        customFields={booking.customFields}
                        updateBookingsChangeList={updateBookingsChangeList} 
                        removeFromUpdateList={removeFromUpdateList}
                    />
                ))
                : res?.map((booking: Booking) => (
                    <BookingCompactCard
                        key={uuid()}
                        bookingId={booking.id}
                        status={booking.status}
                        checkInDate={booking.checkInDateLocal}
                        checkOutDate={booking.checkOutDateLocal}
                        hotelName={booking.hotelName}
                        guests={booking.bookingGuests}
                        customerName={booking.customerName}
                        units={booking.units}
                        onClick={handleBookingClick}
                    />
                ))
            }
            {res?.length === 0 ? (
                <>
                    <div className={classes.emptyDiv}>
                        <img src={emptyAirline} alt="fireSpot" />
                    </div>
                    {isBackoffice && <PrimaryButton
                        onClick={() => {
                            navigate(CREATE_BOOKING);
                            logAmplitudeEvent(amplitudeLogs.CREATE_LOG);
                        }}
                        className={classes.createButton}
                    >
                        New Request
                    </PrimaryButton>}
                </>
            ) : (
                <Stack spacing={2}>
                    <Pagination
                        count={countPages}
                        color="primary"
                        className={classes.pagination}
                        onChange={(_, pageNumber) => {
                            setPage(pageNumber);
                            logAmplitudeEvent(amplitudeLogs.REQUEST_PAGINATION, { Page: pageNumber });
                        }}
                    />
                </Stack>
            )}
        </>
    );
};

export default List;
