import {
    Autocomplete,
    MultipleSelect,
    PrimaryButton,
    TextField,
    Form,
    SecondaryButton,
    MessageDialog,
    FullScreenLoader,
    Spinner,
} from '@get-e/react-components';
import {
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Rating,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useIsFetching, useMutation } from 'react-query';
import moment, { Moment } from 'moment';
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { DateRange, DateRangePicker, MultiInputDateRangeField, TimePicker } from '@mui/x-date-pickers-pro';

import { AddressRequest, Customer } from '../../accounts/api/types';
import {
    AddTravellerResponse,
    Benefit,
    BenefitOption,
    RoomType,
    Traveller,
} from '../../hotels/api/types';
import InputError from '../../../helpers/validation/InputError';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { useTravellers } from '../../hotels/api/useTravellers';
import { useRoomTypes } from '../../hotels/api/useRoomTypes';
import { useBenefits } from '../../hotels/api/useBenefits';
import { DEAFULT_ADD_BUTTON_OPTION, NO_RESULTS_OPTION } from '../../hotels/api/constants';
import { updateRequest } from '../../../services/request';
import and from '../../../helpers/validation/validators/and';
import isNotNull from '../../../helpers/validation/validators/isNotNull';
import isFilledObjectAttributes from '../../../helpers/validation/validators/isFilledObjectAttributes';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import { TIME_FORMATS } from '../../../helpers/timeFormats';
import { getTimeZone } from '../../../services/getTimeZone';
import PageTitle from '../../../components/PageTitle';
import getHelperText from '../../../helpers/validation/getHelperText';
import allValid from '../../../helpers/validation/allValid';
import AddNewTravellerModal from '../../hotels/addNewTravellerModal/AddNewTravellerModal';
import { useRequest } from '../api/useRequest';
import LabeledData from '../../../components/LabeledData';
import { COLORS } from '../../../constans/colors';
import PermissionsGate from '../../../permissions/PermissionsGate';
import { SCOPES } from '../../../permissions/permissionMaps';
import { ALL_TRAVELLERS } from '../../../constans/queryKeys';
import { mapAddResponseToTraveller } from '../../../services/traveller';
import { GooglePlacesAutocomplete } from '../../../components/GooglePlacesAutocomplete';
import { useAuth } from '../../../context/AuthenticatedUserContext';
import isFilledObject from '../../../helpers/validation/validators/isFilledObject';
import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import * as amplitudeLogs from '../../../constans/amplitudeKeys';
import isPastDate from '../../../helpers/validation/validators/isPastDate';
import isAfterTime from '../../../helpers/validation/validators/isAfterTime';
import { Validated } from '../../../helpers/validation/Validator';

const useStyles = makeStyles({
    headingContainer: {
        marginBottom: '1.5rem',
    },
    dateTimeGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        gridColumnGap: '3rem',
    },
    buttonContainer: {
        marginTop: '2rem',
    },
    secondaryText: {
        color: `${COLORS.DARK_GRAY}`,
    },
    addNewContainer: {
        borderTop: `1px solid ${COLORS.DARK_GRAY}`,
        padding: '.75rem 1rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: COLORS.LIGHT_GRAY,
        },
    },
    boxItem: {
        padding: '.75rem 1rem',
        '&:hover': {
            backgroundColor: COLORS.LIGHT_GRAY,
        },
    },
    backButton: {
        marginLeft: '2rem',
    },
    starRatingTitle: {
        marginBottom: '.5rem',
    },
    noResults: {
        textAlign: 'center',
        padding: '.75rem 1rem',
    },
    gridContainer: {
        gridTemplateAreas: `'gridItem1' 'gridItem2' 'gridItem3'`,
    },
    gridContainerMobile: {
        display: 'grid',
        gridTemplateAreas: `'gridItem1' 'gridItem3' 'gridItem2'`,
    },
    searchLoader: {
        position: 'absolute',
        right: '40px',
        top: '45%',
        zIndex: '100',
    },
});

const EditRequest = () => {
    const [address, setAddress] = useState<AddressRequest>({} as AddressRequest);
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Moment>>([null, null]);
    const [checkInTime, setCheckInTime] = useState<Moment | null>();
    const [checkOutTime, setCheckOutTime] = useState<Moment | null>();
    const [openInTime, setOpenInTime] = useState(false);
    const [openOutTime, setOpenOutTime] = useState(false);

    const [traveller, setTraveller] = useState<Traveller | null>(null);
    const [account, setAccount] = useState<Customer | null>(null);
    const [roomTypeIds, setRoomTypeIds] = useState<string[]>([]);
    const [ratingStar, setRatingStar] = useState<number>(1);
    const [comment, setComment] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isTimeIncluded, setIsTimeIncluded] = useState(false);

    const [travellerError, setTravellerError] = useState<InputError | null>(null);
    const [addressError, setAddressError] = useState<InputError | null>(null);
    const [dateRangeError, setDateRangeError] = useState<InputError | null>(null);
    const [checkInTimeError, setCheckInTimeError] = useState<InputError | null>(null);
    const [checkOutTimeError, setCheckOutTimeError] = useState<InputError | null>(null);

    const [isSearchTravellers, setIsSearchingTravellers] = useState(false);
    const [isCheckInDateDisabled, setIsCheckInDateDisabled] = useState(false);
    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { user } = useAuth();
    const { id = '' } = useParams();
    const { data: requestData } = useRequest(parseInt(id, 10));
    const { roomTypes } = useRoomTypes();
    const { benefits } = useBenefits();
    const customerId = useMemo(() => {
        return user?.isBackoffice ? account?.id ?? -1 : user?.customer?.id ?? -1;
    }, [user, account]);
    const {
        travellers = [],
        isLoading,
        refetch: refetchTravellersList,
    } = useTravellers({ search: inputValue, customerId: customerId });

    const isFetchingTravellers = !!useIsFetching([ALL_TRAVELLERS + inputValue]);

    useEffect(() => {
        setIsTimeIncluded(!!checkInTime || !!checkOutTime);
    }, [checkInTime, checkOutTime]);


    useEffect(() => {
        logAmplitudeEvent(amplitudeLogs.EDIT_ADDRESS, { Address: address });
    }, [address]);

    useEffect(() => {
        if (!isOpenModal && inputValue.length > 2) refetchTravellersList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenModal]);

    useEffect(() => {
        setIsSearchingTravellers(inputValue.length <= 2);
    }, [inputValue]);

    useEffect(() => {
        if (!requestData) {
            return;
        }
        const defaultAddressRequest = {
            city: requestData?.request_address?.city,
            state_province: requestData?.request_address?.state_province,
            postal_code: requestData?.request_address?.postal_code,
            country_code: requestData?.request_address?.country?.alpha2,
            address_line: requestData?.request_address?.address_line,
            lat: requestData?.request_address?.lat,
            long: requestData?.request_address?.long,
            name: requestData?.request_address?.short_name,
        };
        setAddress(defaultAddressRequest);
        setComment(requestData?.comments);
        setRatingStar(requestData?.minimum_star_rating);
        // TODO: MORE TRAVELLERS
        const defaultTraveller = {
            email: requestData?.guests[0]?.traveller?.email ?? '',
            employeeNumber: requestData?.guests[0]?.traveller?.employee_number ?? '',
            firstName: requestData?.guests[0]?.traveller?.first_name ?? '',
            lastName: requestData?.guests[0]?.traveller?.last_name ?? '',
            id: requestData?.guests[0]?.traveller?.id ?? '',
        };
        setTraveller(defaultTraveller as Traveller);
        setSelectedDateRange([moment(requestData?.check_in_date_local), moment(requestData?.check_out_date_local)])

        if (!user?.isBackoffice)
            setIsCheckInDateDisabled(
                moment(moment(requestData?.check_in_date_local).toDate()).isBefore(
                    new Date(),
                ),
            );

        if (requestData?.is_time_included) {
            setCheckInTime(moment(requestData?.check_in_date_local));
            setCheckOutTime(moment(requestData?.check_out_date_local));
        }
        const defaultAccount = {
            id: requestData?.customer_id,
            name: requestData?.customer_name,
            address: '',
        };
        setAccount(defaultAccount as Customer);
    }, [requestData]);

    const defaultBenefits = useMemo(() => {
        return requestData?.benefits?.map((benefit: Benefit) => ({
            id: benefit.id,
            name: benefit.name,
            isChecked: true,
        }));
    }, [requestData?.benefits]);

    const allBenefits = useMemo(() => {
        return benefits?.map((benefit: Benefit) => {
            const isDefault = defaultBenefits?.find(
                (item: BenefitOption) => item.id === benefit.id,
            );
            return {
                id: benefit.id,
                name: benefit.name,
                isChecked: isDefault?.isChecked ?? false,
            };
        });
    }, [benefits, defaultBenefits]);

    useEffect(() => {
        setBenefitsWithId(prev => ({
            ...prev,
            benefitOptions: allBenefits as BenefitOption[],
        }));
    }, [allBenefits]);

    const defaultRoomTypes = useMemo(() => {
        return requestData?.room_types.map(roomType => roomType?.name);
    }, [requestData?.room_types]);

    const roomTypesValues: Map<string, string> = useMemo(() => {
        return new Map(
            roomTypes?.map((roomType: RoomType) => [roomType.id, roomType.name]),
        );
    }, [roomTypes]);

    const travellersOptions = useMemo(() => {
        return [...travellers, DEAFULT_ADD_BUTTON_OPTION];
    }, [travellers]);

    const [benefitsWithId, setBenefitsWithId] = useState({
        benefitOptions: allBenefits ?? [],
    });

    const onCheckedHandler = (index: number) => {
        setBenefitsWithId(prev => ({
            ...prev,
            benefitOptions: [
                ...prev?.benefitOptions?.map(({ isChecked, ...rest }, idx) =>
                    idx === index
                        ? { ...rest, isChecked: !isChecked }
                        : { ...rest, isChecked },
                ),
            ],
        }));
    };

    const { mutate: updateRequestMutation, isLoading: isLoadingEdit } = useMutation(
        updateRequest,
        {
            onSuccess: () => {
                logAmplitudeEvent(amplitudeLogs.EDIT_SAVE_REQUEST_SUCCESS);
                navigate(-1);
                showNotification('Request successfully edited.', Severity.Info);
            },
            onError: (error: Error) => {
                logAmplitudeEvent(amplitudeLogs.EDIT_SAVE_REQUEST_FAILED);
                setIsOpenConfirmModal(false);
                showNotification(t('errors.retry'), Severity.Error);
            },
        },
    );

    const isNotPast = moment(moment(requestData?.check_in_date_local).toDate()).isBefore(
        new Date(),
    );

    const validateFormFields = (): boolean => {
        const validated = {
            checkInOutDate: and(isNotNull(selectedDateRange?.[0], InputError.Required), () => isNotNull(selectedDateRange?.[1], InputError.Required)),
            traveller: isFilledObjectAttributes(traveller, InputError.Required),
            address: isFilledObject(address, InputError.Required),
        } as any;

        const isSameDate =  selectedDateRange?.[0]?.format(DATE_FORMATS['YYYY-MM-DD']) === selectedDateRange?.[1]?.format(DATE_FORMATS['YYYY-MM-DD']);
        if (isTimeIncluded) {
            validated.checkInTime = isNotNull(checkInTime, InputError.Required);
            validated.checkOutTime = and(isNotNull(checkOutTime, InputError.Required),
            () => isSameDate
            ? isAfterTime(checkOutTime?.toDate() || null, checkInTime?.toDate() || null, InputError.InvalidAfterTime) : { 
                isValid: true,
                value: new Date()
               } as Validated<Date, InputError>
            );
        }

        if (!allValid(validated)) {
            setDateRangeError(validated.checkInOutDate.isValid ? null : InputError.Required);
            
            if (isTimeIncluded) {
                setCheckInTimeError(
                    validated.checkInTime.isValid ? null : validated.checkInTime.error,
                );
                setCheckOutTimeError(
                    validated.checkOutTime.isValid ? null : validated.checkOutTime.error,
                );
            }

            setTravellerError(
                validated.traveller.isValid ? null : validated.traveller?.error,
            );

            setAddressError(validated.address.isValid ? null : validated.address?.error);

            return false;
        }
        return true;
    };

    const renderAddNewButton = () => {
        return (
            <Box
                component="li"
                className={classes.addNewContainer}
                onClick={() => {
                    setIsOpenModal(true);
                    logAmplitudeEvent(amplitudeLogs.EDIT_ADD_NEW_TRAVELLER_BUTTON);
                    logAmplitudeEvent(amplitudeLogs.EDIT_ADD_NEW_TRAVELLER_MODAL);
                }}
            >
                + Add new
            </Box>
        );
    };

    const handleSubmit = () => {
        logAmplitudeEvent(amplitudeLogs.EDIT_SAVE_REQUEST);

        if (validateFormFields()) {
            setIsOpenConfirmModal(true);
        }
    };

    const handleSave = async () => {
        const benefitIds = benefitsWithId?.benefitOptions
            ?.filter(benefit => benefit.isChecked)
            .map(benefit => benefit.id);

        const timeIn = checkInTime
            ? moment(checkInTime).format(TIME_FORMATS['HH:mm:ss'])
            : '';
        const timeOut = checkOutTime
            ? moment(checkOutTime).format(TIME_FORMATS['HH:mm:ss'])
            : '';
        const defaultDate = moment();
        const checkInDateUtc = selectedDateRange?.[0]?.utc();
        const timeZone = await getTimeZone(
            address.lat ?? '',
            address.long ?? '',
            (checkInDateUtc?.valueOf() ?? defaultDate.valueOf()) / 1000,
        );
        updateRequestMutation({
            checkInDate: selectedDateRange?.[0]?.format(DATE_FORMATS['YYYY-MM-DD']) || '',
            checkOutDate: selectedDateRange?.[1]?.format(DATE_FORMATS['YYYY-MM-DD']) || '',
            isTimeIncluded: !!timeIn && !!timeOut,
            checkInTime: timeIn,
            checkOutTime: timeOut,
            minimumStarRating: ratingStar,
            comments: comment ?? '',
            roomTypeIds,
            benefitIds,
            requestAddress: {
                shortName: address.name ?? '',
                addressLine: address.address_line ?? '',
                postalCode: address.postal_code ?? '',
                city: address.city ?? '',
                stateProvince: address.state_province ?? '',
                countryCode: address.country_code ?? '',
                lat: address.lat?.toString() ?? '',
                long: address?.long?.toString() ?? '',
                timeZone: timeZone?.timeZoneId,
            },
            travellerId: traveller?.id,
            id,
        });
    };
    const handleCheckInTime = (newCheckInTime: Moment | null) => {
        setCheckInTime(newCheckInTime);
        fixCheckOutTime(newCheckInTime);
        setCheckInTimeError(null)
    };

    const fixCheckOutTime = (newCheckInTime: Moment | null) => {
        if (
            moment(selectedDateRange?.[0]).format(DATE_FORMATS['YYYY-MM-DD']) ===
            moment(selectedDateRange?.[1]).format(DATE_FORMATS['YYYY-MM-DD'])
        ) {
            if (moment(newCheckInTime).hour() > moment(checkOutTime).hour()) {
                setCheckOutTime(moment(newCheckInTime).add(1, 'hours'));
            }
            if (
                moment(newCheckInTime).hour() === moment(checkOutTime).hour() &&
                moment(newCheckInTime).minute() > moment(checkOutTime).minute()
            ) {
               setCheckOutTime(moment(newCheckInTime).add(30, 'minutes'));
            }
        }
    };

    const handleTravellerCreated = (traveller: AddTravellerResponse) => {
        setInputValue(`${traveller.first_name} ${traveller.last_name}`);
        setTraveller(mapAddResponseToTraveller(traveller));
        setIsOpenModal(false);
    };

    if (!requestData) {
        return <FullScreenLoader />;
    }

    return (
        <>
            <div className={classes.headingContainer}>
                <PageTitle title={'Edit request'} />
            </div>
            <Form onSubmit={handleSubmit}>
                <Grid
                    container
                    spacing={4}
                    className={
                        isMobile ? classes.gridContainerMobile : classes.gridContainer
                    }
                >
                    <Grid item xs={12} sm={6} sx={{ gridArea: 'gridItem1' }}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container columnSpacing={1} rowSpacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <LabeledData
                                            label="Request"
                                            labelColumnNumber={12}
                                            labelColor={COLORS.BLUE}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <GooglePlacesAutocomplete
                                            error={addressError}
                                            helperText={getHelperText(addressError, t)}
                                            onChangeError={setAddressError}
                                            onChangeAddress={setAddress}
                                            label="Search airports, cities or points of interest"
                                            defaultValue={requestData?.request_address}
                                        />
                                    </Grid>
                                <Grid item xs={12}>
                                <DateRangePicker
                                    localeText={{ start: 'Check-in', end: 'Check-out' }}
                                    value={selectedDateRange}
                                    onChange={newValue => {
                                        setSelectedDateRange(newValue);
                                        setDateRangeError(null);
                                     }}
                                    disablePast={!user?.isBackoffice}
                                    format={DATE_FORMATS['DD MMM YYYY']}
                                    slotProps={{
                                        field: { dateSeparator: 'to' },
                                        textField: {
                                            variant: "filled", 
                                            InputProps: { endAdornment: <CalendarTodayOutlinedIcon style={{ color: COLORS.SLATE_GREY, fontSize: '1.5rem' }} /> },
                                            required: true,
                                            error: dateRangeError !== null,
                                            helperText: getHelperText(dateRangeError, t),
                                       },
                                        day: {
                                            sx: {
                                            "& .MuiDateRangePickerDay-dayInsideRangeInterval": {
                                                backgroundColor: "rgba(25, 118, 210, 0.12)",                     
                                            },
                                            "& .MuiDateRangePickerDay-rangeIntervalDayPreview": {
                                                borderRadius: '50%',
                                                borderStyle: 'dashed',
                                                borderColor: "rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12)",
                                                transition: 'none'
                                                },
                                                "& .MuiDateRangePickerDay-dayOutsideRangeInterval": {
                                                    ":hover":{
                                                        border: "1px solid rgb(158, 158, 158)"
                                                    }
                                                },
                                                '& .MuiDateRangePickerDay-dayOutsideRangeInterval:hover': {
                                                    border: '1px solid rgb(158, 158, 158)',
                                                }
                                            },
                                        },

                                    }}
                                    slots={{ field: MultiInputDateRangeField }}
                                    onClose={() => {
                                        logAmplitudeEvent(
                                            amplitudeLogs.EDIT_CHECK_OUT_DATE,
                                        );
                                        logAmplitudeEvent(
                                            amplitudeLogs.EDIT_CHECK_IN_DATE,
                                        );
                                    }}
                                />
                                </Grid>
                                <Grid item xs={5.5}>
                                <TimePicker
                                     label="Check-in time"
                                     value={checkInTime}
                                     onChange={newValue => handleCheckInTime(newValue)}
                                     format={TIME_FORMATS['HH:mm A']}
                                     onAccept={() => setOpenInTime(true)}
                                     minutesStep={15}
                                     onClose={() => {
                                         logAmplitudeEvent(
                                             amplitudeLogs.EDIT_CHECK_IN_TIME,
                                         )
                                         setOpenInTime(false);
                                        }
                                     }
                                     slotProps={{
                                        textField: {
                                            error: checkInTimeError !== null,
                                            helperText: getHelperText(checkInTimeError, t),
                                            variant: 'filled',
                                            inputProps: { readOnly: true },
                                            onClick: () => setOpenInTime(true),
                                        },
                                        field: { clearable: true },
                                    }}
                                    open={openInTime}
                                />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography></Typography>
                                </Grid>
                                <Grid item xs={5.5}>
                                <TimePicker
                                     label="Check-out time"
                                     value={checkOutTime}
                                     onChange={newValue => {
                                        setCheckOutTime(newValue);
                                        setCheckOutTimeError(null);
                                     }}
                                     format={TIME_FORMATS['HH:mm A']}
                                     onAccept={() => setOpenOutTime(true)}
                                     minutesStep={15}
                                     onClose={() => {
                                         logAmplitudeEvent(
                                             amplitudeLogs.EDIT_CHECK_OUT_TIME,
                                         )
                                         setOpenOutTime(false);
                                        }
                                     }
                                     slotProps={{
                                        textField: {
                                            variant: 'filled',
                                            inputProps: { readOnly: true },
                                            onClick: () => setOpenOutTime(true),
                                            error: checkOutTimeError !== null,
                                            helperText: getHelperText(checkOutTimeError, t),
                                        },
                                        field: { clearable: true },
                                    }}
                                    open={openOutTime}
                                />
                                </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Special requests (optional)"
                                            value={comment}
                                            onChange={event =>
                                                setComment(event.target.value)
                                            }
                                            onFocus={() =>
                                                logAmplitudeEvent(
                                                    amplitudeLogs.EDIT_SPECIAL_REQUEST,
                                                )
                                            }
                                            multiline
                                            rows={4}
                                            noHelperTextSpace
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card variant="outlined" sx={{ overflow: 'visible' }}>
                            <CardContent>
                                <Grid container columnSpacing={1} rowSpacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <LabeledData
                                            label="Traveller"
                                            labelColumnNumber={12}
                                            labelColor={COLORS.BLUE}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            position: 'relative',
                                        }}
                                    >
                                        {isFetchingTravellers && (
                                            <Spinner
                                                className={classes.searchLoader}
                                                size={25}
                                            />
                                        )}
                                        <Autocomplete
                                            required
                                            filterOptions={options => {
                                                if (options.length === 1) {
                                                    options.unshift(NO_RESULTS_OPTION);
                                                }
                                                return options;
                                            }}
                                            isError={travellerError !== null}
                                            helperText={getHelperText(travellerError, t)}
                                            label="Add traveller name"
                                            loading={isLoading}
                                            options={travellersOptions}
                                            getOptionLabel={option =>
                                                option.firstName + ' ' + option.lastName
                                            }
                                            renderOption={(prop, option: Traveller) => {
                                                if (option.id === 'NO_RESULTS') {
                                                    if (isSearchTravellers)
                                                        return (
                                                            <Box
                                                                component="li"
                                                                {...prop}
                                                                className={
                                                                    classes.noResults
                                                                }
                                                                onClick={() => {}}
                                                            >
                                                                {inputValue.length ? (
                                                                    <Spinner size={25} />
                                                                ) : (
                                                                    'Start typing to search'
                                                                )}
                                                            </Box>
                                                        );
                                                    return (
                                                        <Box
                                                            component="li"
                                                            {...prop}
                                                            className={classes.noResults}
                                                            onClick={() => {}}
                                                        >
                                                            No results found
                                                        </Box>
                                                    );
                                                }
                                                if (option.id === 'ADD')
                                                    return (
                                                        <PermissionsGate
                                                            scopes={[
                                                                SCOPES.canAddTraveller,
                                                            ]}
                                                        >
                                                            {renderAddNewButton()}
                                                        </PermissionsGate>
                                                    );

                                                return (
                                                    <Box
                                                        component="li"
                                                        {...prop}
                                                        className={classes.boxItem}
                                                    >
                                                        <span>
                                                            {option.firstName}{' '}
                                                            {option.lastName}
                                                        </span>
                                                        <span
                                                            className={
                                                                classes.secondaryText
                                                            }
                                                        >
                                                            &nbsp;{option.employeeNumber}
                                                        </span>
                                                    </Box>
                                                );
                                            }}
                                            value={traveller}
                                            onChange={(_, newValue) => {
                                                setTraveller(newValue ?? null);
                                                setTravellerError(null);
                                                logAmplitudeEvent(
                                                    amplitudeLogs.EDIT_ADD_TRAVELLER,
                                                    {
                                                        Traveller: newValue,
                                                    },
                                                );
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            onFocus={() =>
                                                logAmplitudeEvent(
                                                    amplitudeLogs.EDIT_ADD_TRAVELLER,
                                                )
                                            }
                                            noOptionsText={renderAddNewButton()}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <div className={classes.buttonContainer}>
                            <PrimaryButton
                                onClick={handleSubmit}
                                submitsForm
                                loading={isLoadingEdit}
                            >
                                Save
                            </PrimaryButton>
                            <SecondaryButton
                                onClick={() => {
                                    navigate(-1);
                                    logAmplitudeEvent(amplitudeLogs.EDIT_BACK);
                                }}
                                className={classes.backButton}
                            >
                                Back
                            </SecondaryButton>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ gridArea: 'gridItem3' }}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container columnSpacing={1} rowSpacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <LabeledData
                                            label="Travel policy"
                                            labelColumnNumber={12}
                                            labelColor={COLORS.BLUE}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            component="legend"
                                            className={classes.starRatingTitle}
                                        >
                                            Minimum star-rating
                                        </Typography>
                                        <Rating
                                            value={ratingStar}
                                            onChange={(_, newValue) => {
                                                setRatingStar(newValue ?? 0);
                                                logAmplitudeEvent(
                                                    amplitudeLogs.EDIT_STAR_RATING,
                                                    {
                                                        StarRating: newValue,
                                                    },
                                                );
                                            }}
                                            onFocus={() =>
                                                logAmplitudeEvent(
                                                    amplitudeLogs.EDIT_STAR_RATING,
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {Object.keys(roomTypes ?? []).length !== 0 && (
                                            <MultipleSelect
                                                label="Allowed room type(s)"
                                                value={roomTypeIds}
                                                values={roomTypesValues}
                                                onSetIds={setRoomTypeIds}
                                                defaultSelectedValue={defaultRoomTypes}
                                                onFocus={() =>
                                                    logAmplitudeEvent(
                                                        amplitudeLogs.EDIT_ROOM_TYPE,
                                                    )
                                                }
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormGroup>
                                            {benefitsWithId?.benefitOptions?.map(
                                                (benefit, index) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    benefit.isChecked
                                                                }
                                                                onChange={event =>
                                                                    onCheckedHandler(
                                                                        index,
                                                                    )
                                                                }
                                                                key={uuid()}
                                                            />
                                                        }
                                                        label={benefit?.name}
                                                        value={benefit?.id}
                                                        key={uuid()}
                                                        onFocus={() =>
                                                            logAmplitudeEvent(
                                                                benefit?.name ===
                                                                    'Include breakfest'
                                                                    ? amplitudeLogs.EDIT_INCLUDE_BREAKFEST
                                                                    : amplitudeLogs.EDIT_INCLUDE_PARKING,
                                                            )
                                                        }
                                                    />
                                                ),
                                            )}
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    {isOpenModal && (
                        <AddNewTravellerModal
                            isOpen={isOpenModal}
                            onClose={() => {
                                setIsOpenModal(false);
                            }}
                            onTravellerCreated={handleTravellerCreated}
                            customerId={customerId}
                        />
                    )}
                    {isOpenConfirmModal && (
                        <MessageDialog
                            title="Are you sure you want to edit?"
                            onClose={() => setIsOpenConfirmModal(false)}
                            onConfirm={() => handleSave()}
                            closeButtonLabel="Cancel"
                            confirmButtonLabel="Save"
                            isConfirmButtonDisplayed
                            closeButtonType="secondary"
                        />
                    )}
                </Grid>
            </Form>
        </>
    );
};

export default EditRequest;
