const ROUTE_PREFIX = 'api/';
const ID_PARAM = 'id';
const CUSTOMER_ID_PARAM = 'customerId';
const HOTEL_ID_PARAM = 'hotelId';
const SEARCH_PARAM = 'search_param';
const LAT_PARAM = 'lat_param';
const LONG_PARAM = 'long_param';
const USER_INVITED_ID = 'revoke_invited_id';

export const CSRF_COOKIE = '/sanctum/csrf-cookie';
export const LOGOUT = `${ROUTE_PREFIX}logout`;
export const LOGIN = `${ROUTE_PREFIX}login`;
export const CUSTOMERS = `${ROUTE_PREFIX}customers?search=${SEARCH_PARAM}`;
export const CUSTOMER = `${ROUTE_PREFIX}customers`;
export const CREATE_CUSTOMER = `${CUSTOMER}/create`;
export const CUSTOMER_ROLES = `${CUSTOMER}/${ID_PARAM}/roles`;
export const INVITE_USER = `${ROUTE_PREFIX}users/invite`;
export const ACCEPT_USER_INVITE = `${ROUTE_PREFIX}users/accept-invite`;
export const FORGOT_PASSWORD = `${ROUTE_PREFIX}users/forgot-password`;
export const RESET_PASSWORD = `${ROUTE_PREFIX}users/reset-password`;
export const HOTELS = `${ROUTE_PREFIX}hotels`;
export const HOTELS_NEARBY = `${HOTELS}/nearby?lat=${LAT_PARAM}&long=${LONG_PARAM}&search=${SEARCH_PARAM}`;
export const CREATE_HOTELS = `${HOTELS}/create`;

export const HOTEL_ROLES = `${HOTELS}/${ID_PARAM}/roles`;
export const INVITE_HOTEL_USER = `${HOTELS}/users/invite`;

export const ACTIVE_AND_DEACTIVATED_HOTEL_USERS = `${HOTELS}/${HOTEL_ID_PARAM}/users`;
export const INVITED_HOTEL_USERS = `${HOTELS}/${HOTEL_ID_PARAM}/user-invites`;
export const REVOKE_HOTEL_USER_INVITATION = `${HOTELS}/${HOTEL_ID_PARAM}/user-invites/${USER_INVITED_ID}`;

export const USERS = `${ROUTE_PREFIX}users`;
export const ACTIVE_AND_DEACTIVATED_USERS = `${CUSTOMER}/${CUSTOMER_ID_PARAM}/users`;
export const INVITED_USERS = `${CUSTOMER}/${CUSTOMER_ID_PARAM}/user_invites`;
export const REVOKE_INVITATION = `${CUSTOMER}/${CUSTOMER_ID_PARAM}/user_invites/${USER_INVITED_ID}`;

export const ADD_TRAVELLER = `${ROUTE_PREFIX}travellers`;
export const TRAVELLERS = `${ROUTE_PREFIX}customers/${CUSTOMER_ID_PARAM}/travellers?search=${SEARCH_PARAM}`;
export const ROOM_TYPES = `${ROUTE_PREFIX}room-types`;
export const BENEFITS = `${ROUTE_PREFIX}benefits`;

export const REQUESTS = `${ROUTE_PREFIX}requests`;
export const REQUESTS_SEARCH = `${ROUTE_PREFIX}requests/search`;
export const CREATE_REQUEST = `${REQUESTS}`;
export const CANCEL_REQUEST = `${REQUESTS}/cancel`;

export const BOOKINGS = `${ROUTE_PREFIX}bookings`;
export const OPTION_SEARCH_FOR_BOOKINGS = `${ROUTE_PREFIX}bookings/search`;

export const HOTEL_CONFIRM_BOOKING= `${HOTELS}/bookings/confirm`;

export const getCustomers = (search: string = '') =>
    CUSTOMERS.replace(SEARCH_PARAM, search);

export const getCustomerRolesRoute = (id: number) =>
    CUSTOMER_ROLES.replace(ID_PARAM, id.toString());

export const getInvitedPeopleRoute = (id: number) =>
    INVITED_USERS.replace(CUSTOMER_ID_PARAM, id.toString());

export const getActiveAndDeactivatedRoute = (id: number) =>
    ACTIVE_AND_DEACTIVATED_USERS.replace(CUSTOMER_ID_PARAM, id.toString());

export const getTravellers = (id: number, search: string) =>
    TRAVELLERS.replace(CUSTOMER_ID_PARAM, id.toString()).replace(SEARCH_PARAM, search);

export const getHotelsNearbyRoute = (lat: string, long: string, search: string = '') =>
    HOTELS_NEARBY.replace(LAT_PARAM, lat)
        .replace(LONG_PARAM, long)
        .replace(SEARCH_PARAM, search);

export const getRevokeInvitationRoute = (customerId: number, userInviteId: number) =>
    REVOKE_INVITATION.replace(CUSTOMER_ID_PARAM, customerId.toString()).replace(
        USER_INVITED_ID,
        userInviteId.toString(),
    );

export const TIME_ZONE = `https://maps.googleapis.com/maps/api/timezone/json`;

export const getHotelRolesRoute = (id: number) =>
    HOTEL_ROLES.replace(ID_PARAM, id.toString());

export const getActiveAndDeactivatedHotelUsersRoute = (id: number) =>
    ACTIVE_AND_DEACTIVATED_HOTEL_USERS.replace(HOTEL_ID_PARAM, id.toString());

export const getInvitedHotelUsersRoute = (id: number) =>
    INVITED_HOTEL_USERS.replace(HOTEL_ID_PARAM, id.toString());

export const getRevokeHotelUserInvitationRoute = (hotelId: number, userInviteId: number) =>
    REVOKE_HOTEL_USER_INVITATION.replace(HOTEL_ID_PARAM, hotelId.toString()).replace(
        USER_INVITED_ID,
        userInviteId.toString(),
    );