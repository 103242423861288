const FIELD = `<FIELD>`;
const MODAL = '<MODAL>';
const BUTTON = '<BUTTON>';
const INPUT = '<INPUT>';
const SCREEN = '<SCREEN>';
const MENU = '<MENU>';
const TAB = '<TAB>';
const LISTITEM = '<LISTITEM>';
const PAGINATION = '<PAGINATION>';

export const CREATE_REQUEST = 'Create request';
export const CREATE_LOG = `${SCREEN} ${CREATE_REQUEST}`;
export const CREATE_SAVE_REQUEST = `${BUTTON} ${CREATE_REQUEST}: Save request`;
export const CREATE_SAVE_REQUEST_SUCCESS = `${CREATE_SAVE_REQUEST}: Success`;
export const CREATE_SAVE_REQUEST_FAILED = `${CREATE_SAVE_REQUEST}: Failed`;

export const CREATE_GO_TO_BOOKINGS = `${BUTTON} ${CREATE_REQUEST}: Go to bookings`;
export const CREATE_ADDRESS = `${INPUT} ${CREATE_REQUEST}: Address`;
export const CREATE_CHECK_IN_DATE = `${FIELD} ${CREATE_REQUEST}: Check in date`;
export const CREATE_CHECK_OUT_DATE = `${FIELD} ${CREATE_REQUEST}: Check out date`;
export const CREATE_CHECK_IN_TIME = `${FIELD} ${CREATE_REQUEST}: Check in time`;
export const CREATE_CHECK_OUT_TIME = `${FIELD} ${CREATE_REQUEST}: Check out time`;
export const CREATE_SPECIAL_REQUEST = `${INPUT} ${CREATE_REQUEST}: Special request`;
export const CREATE_STAR_RATING = `${FIELD} ${CREATE_REQUEST}: Minimum star rating`;
export const CREATE_ROOM_TYPE = `${FIELD} ${CREATE_REQUEST}: Room type`;
export const CREATE_INCLUDE_BREAKFEST = `${FIELD} ${CREATE_REQUEST}: Include breakfast`;
export const CREATE_INCLUDE_PARKING = `${FIELD} ${CREATE_REQUEST}: Inlcude parking`;
export const CREATE_ACCOUNT = `${FIELD} ${CREATE_REQUEST}: Account`;
export const CREATE_ADD_TRAVELLER = `${INPUT} ${CREATE_REQUEST}: Add traveller`;

export const NEW_TRAVELLER = 'Add new traveller';
export const ADD_NEW_TRAVELLER_MODAL = `${MODAL} ${CREATE_REQUEST}: ${NEW_TRAVELLER}`;
export const ADD_NEW_TRAVELLER_BUTTON = `${BUTTON} ${CREATE_REQUEST}: ${NEW_TRAVELLER}`;
export const ADD_NEW_TRAVELLER_FIRST_NAME = `${INPUT} ${NEW_TRAVELLER}: First name`;
export const ADD_NEW_TRAVELLER_LAST_NAME = `${INPUT} ${NEW_TRAVELLER}: Last name`;
export const ADD_NEW_TRAVELLER_EMAIL = `${INPUT} ${NEW_TRAVELLER}: Email`;
export const ADD_NEW_TRAVELLER_MOBILE = `${INPUT} ${NEW_TRAVELLER}: Mobile`;
export const ADD_NEW_TRAVELLER_EMPLOYEE = `${INPUT} ${NEW_TRAVELLER}: Employee number`;
export const ADD_NEW_TRAVELLER_ADD = `${BUTTON} ${NEW_TRAVELLER}: Add`;
export const ADD_NEW_TRAVELLER_ADD_SUCCESS = `${ADD_NEW_TRAVELLER_ADD}: Success`;
export const ADD_NEW_TRAVELLER_ADD_FAILED = `${ADD_NEW_TRAVELLER_ADD}: Failed`;
export const ADD_NEW_TRAVELLER_CANCEL = `${BUTTON} ${NEW_TRAVELLER}: Cancel`;

export const USERS = `Users overview`;
export const USERS_OVERVIEW = `${SCREEN} ${USERS}`;
export const USERS_ACTIVE = `${TAB} ${USERS}: Active`;
export const USERS_INVITED = `${TAB} ${USERS}: Invited`;
export const USERS_DEACTIVATED = `${TAB} ${USERS}: Deactivated`;
export const USERS_INVITE = `${MODAL} ${USERS}: Invite`;
export const USERS_INVITE_BUTTON = `${BUTTON} ${USERS}: Invite`;

export const EDIT_USER = `Edit user`;
export const EDIT_USER_ACTIVE =  `${LISTITEM} ${USERS}: Active: ${EDIT_USER}`;
export const EDIT_USER_DEACTIVATED =  `${LISTITEM} ${USERS}: Deactivated: ${EDIT_USER}`;
export const EDIT_USER_SCREEN = `${SCREEN} ${EDIT_USER}`;
export const EDIT_USER_FIRST_NAME = `${INPUT} ${EDIT_USER}: First name`;
export const EDIT_USER_LAST_NAME = `${INPUT} ${EDIT_USER}: Last name`;
export const EDIT_USER_ROLE = `${FIELD} ${EDIT_USER}: Role`;
export const EDIT_USER_DEACTIVATE = `${FIELD} ${EDIT_USER}: Deactivate`;
export const EDIT_USER_SAVE = `${BUTTON} ${EDIT_USER}: Save`;
export const EDIT_USER_SAVE_SUCCESS = `${EDIT_USER_SAVE}: Success`;
export const EDIT_USER_SAVE_FAILED = `${EDIT_USER_SAVE}: Failed`;
export const EDIT_USER_BACK = `${BUTTON} ${EDIT_USER}: Back`;
export const EDIT_USER_REVOKE = `${BUTTON} ${EDIT_USER}: Revoke invited user`;

export const INVITE = `${USERS}: Invite`;
export const INVITE_EMAIL = `${INPUT} ${INVITE}: Email`;
export const INVITE_ROLE = `${FIELD} ${INVITE}: Role`;
export const INVITE_SEND = `${BUTTON} ${INVITE}: Send`;
export const INVITE_SEND_SUCCESS = `${INVITE_SEND}: Success`;
export const INVITE_SEND_FAILED =`${INVITE_SEND}: Failed`;
export const INVITE_CANCEL = `${BUTTON} ${INVITE}: Cancel`;


export const REQUESTS = 'Requests overview';
export const REQUEST = `Request`;

export const REQUESTS_SEARCH = `${INPUT} ${REQUESTS}: Search`;
export const LOG_REQUESTS_PENDING = `${TAB} ${REQUESTS}: Pending`;
export const LOG_REQUESTS_UPCOMING = `${TAB} ${REQUESTS}: Upcoming`;
export const LOG_REQUESTS_INPROGRESS = `${TAB} ${REQUESTS}: In Progress`;
export const LOG_REQUESTS_ARCHIVE = `${TAB} ${REQUESTS}: Archive`;
export const REQUEST_OVERVIEW = `${LISTITEM} ${REQUESTS}: List`;
export const REQUEST_EDIT = `${BUTTON} ${REQUEST}: Edit`;
export const REQUEST_CANCEL = `${BUTTON} ${REQUEST}: Cancel`;
export const REQUEST_CANCEL_CONFIRM = `${BUTTON} ${REQUEST}: Cancel: Confirm`;
export const REQUEST_CANCEL_CLOSE = `${BUTTON} ${REQUEST}: Cancel: Close`;
export const REQUEST_PAGINATION = `${PAGINATION} ${REQUEST}: Pagination`;

export const BOOKINGS = 'Booking overview';
export const BOOKING = `Booking`;
export const BOOKING_ADD = `${BUTTON} ${BOOKING}: Add`;
export const BOOKING_EDIT = `${BUTTON} ${BOOKING}: Edit`;
export const BOOKING_OVERVIEW = `${LISTITEM} ${BOOKINGS}: List`;


export const EDIT_REQUEST = 'Edit request';
export const EDIT_SAVE_REQUEST = `${BUTTON} ${EDIT_REQUEST}: Save request`;
export const EDIT_SAVE_REQUEST_SUCCESS = `${EDIT_SAVE_REQUEST}: Success`;
export const EDIT_SAVE_REQUEST_FAILED = `${EDIT_SAVE_REQUEST}: Failed`;

export const EDIT_BACK = `${BUTTON} ${EDIT_REQUEST}: Back`;
export const EDIT_ADDRESS = `${INPUT} ${EDIT_REQUEST}: Address`;
export const EDIT_CHECK_IN_DATE = `${FIELD} ${EDIT_REQUEST}: Check in date`;
export const EDIT_CHECK_OUT_DATE = `${FIELD} ${EDIT_REQUEST}: Check out date`;
export const EDIT_CHECK_IN_TIME = `${FIELD} ${EDIT_REQUEST}: Check in time`;
export const EDIT_CHECK_OUT_TIME = `${FIELD} ${EDIT_REQUEST}: Check out time`;
export const EDIT_SPECIAL_REQUEST = `${INPUT} ${EDIT_REQUEST}: Special request`;
export const EDIT_STAR_RATING = `${FIELD} ${EDIT_REQUEST}: Minimum star rating`;
export const EDIT_ROOM_TYPE = `${FIELD} ${EDIT_REQUEST}: Room type`;
export const EDIT_INCLUDE_BREAKFEST = `${FIELD} ${EDIT_REQUEST}: Include breakfast`;
export const EDIT_INCLUDE_PARKING = `${FIELD} ${EDIT_REQUEST}: Inlcude parking`;
export const EDIT_ACCOUNT = `${FIELD} ${EDIT_REQUEST}: Account`;
export const EDIT_ADD_TRAVELLER = `${INPUT} ${EDIT_REQUEST}: Add traveller`;

export const EDIT_ADD_NEW_TRAVELLER_MODAL = `${MODAL} ${EDIT_REQUEST}: ${NEW_TRAVELLER}`;
export const EDIT_ADD_NEW_TRAVELLER_BUTTON = `${BUTTON} ${EDIT_REQUEST}: ${NEW_TRAVELLER}`;

export const CREATE_BOOKING = `Create booking`;
export const CREATE_BOOKING_HOTEL = `${INPUT} ${CREATE_BOOKING}: Hotel name`;
export const CREATE_BOOKING_CONFIRMATION_NUMBER = `${INPUT} ${CREATE_BOOKING}: Booking confirmation number`;
export const CREATE_BOOKING_STATUS = `${INPUT} ${CREATE_BOOKING}: Status`;
export const CREATE_BOOKING_CHECK_IN_DATE = `${FIELD} ${CREATE_BOOKING}: Check in date`;
export const CREATE_BOOKING_CHECK_OUT_DATE = `${FIELD} ${CREATE_BOOKING}: Check out date`;
export const CREATE_BOOKING_CHECK_IN_TIME = `${FIELD} ${CREATE_BOOKING}: Check in time`;
export const CREATE_BOOKING_CHECK_OUT_TIME = `${FIELD} ${CREATE_BOOKING}: Check out time`;
export const CREATE_BOOKING_SPECIAL_REQUEST = `${INPUT} ${CREATE_BOOKING}: Special request`;
export const CREATE_BOOKING_INCLUDE_BREAKFEST = `${FIELD} ${CREATE_BOOKING}: Include breakfast`;
export const CREATE_BOOKING_INCLUDE_PARKING = `${FIELD} ${CREATE_BOOKING}: Inlcude parking`;
export const CREATE_BOOKING_SEND_EMAIL_HOTEL = `${FIELD} ${CREATE_BOOKING}: Send email to hotel`;
export const CREATE_BOOKING_SEND_EMAIL_BOOKER = `${FIELD} ${CREATE_BOOKING}: Send email to booker`;
export const CREATE_BOOKING_SEND_EMAIL_TRAVELLER = `${FIELD} ${CREATE_BOOKING}: Send email to traveller`;
export const CREATE_BOOKING_SAVE = `${BUTTON} ${CREATE_BOOKING}: Save`;
export const CREATE_BOOKING_SAVE_SUCCESS = `${CREATE_BOOKING_SAVE}: Success`;
export const CREATE_BOOKING_SAVE_FAILED = `${CREATE_BOOKING_SAVE}: Failed`;
export const CREATE_BOOKING_BACK =  `${BUTTON} ${CREATE_BOOKING}: Back`;

export const EDIT_BOOKING = `Edit booking`;
export const EDIT_BOOKING_HOTEL = `${INPUT} ${EDIT_BOOKING}: Hotel name`;
export const EDIT_BOOKING_CONFIRMATION_NUMBER = `${INPUT} ${EDIT_BOOKING}: Booking confirmation number`;
export const EDIT_BOOKING_STATUS = `${INPUT} ${EDIT_BOOKING}: Status`;
export const EDIT_BOOKING_CHECK_IN_DATE = `${FIELD} ${EDIT_BOOKING}: Check in date`;
export const EDIT_BOOKING_CHECK_OUT_DATE = `${FIELD} ${EDIT_BOOKING}: Check out date`;
export const EDIT_BOOKING_CHECK_IN_TIME = `${FIELD} ${EDIT_BOOKING}: Check in time`;
export const EDIT_BOOKING_CHECK_OUT_TIME = `${FIELD} ${EDIT_BOOKING}: Check out time`;
export const EDIT_BOOKING_SPECIAL_REQUEST = `${INPUT} ${EDIT_BOOKING}: Special request`;
export const EDIT_BOOKING_INCLUDE_BREAKFEST = `${FIELD} ${EDIT_BOOKING}: Include breakfast`;
export const EDIT_BOOKING_INCLUDE_PARKING = `${FIELD} ${EDIT_BOOKING}: Inlcude parking`;
export const EDIT_BOOKING_SEND_EMAIL_HOTEL_REQUESTED = `${FIELD} ${EDIT_BOOKING}: Send email to hotel - Subject: Booking requested `;
export const EDIT_BOOKING_SEND_EMAIL_HOTEL_UPDATED = `${FIELD} ${EDIT_BOOKING}: Send email to hotel - Subject: Booking updated `;
export const EDIT_BOOKING_SEND_EMAIL_HOTEL_CANCELLED = `${FIELD} ${EDIT_BOOKING}: Send email to hotel - Subject: Booking cancelled `;
export const EDIT_BOOKING_SEND_EMAIL_BOOKER_CANCELLED = `${FIELD} ${EDIT_BOOKING}: Send email to booker - Subject: Booking cancelled`;
export const EDIT_BOOKING_SEND_EMAIL_BOOKER_UPDATED  = `${FIELD} ${EDIT_BOOKING}: Send email to booker - Subject: Booking updated`;
export const EDIT_BOOKING_SEND_EMAIL_BOOKER_CONFIRMED  = `${FIELD} ${EDIT_BOOKING}: Send email to booker - Subject: Booking confirmed`;
export const EDIT_BOOKING_SEND_EMAIL_TRAVELLER_CANCELLED = `${FIELD} ${EDIT_BOOKING}: Send email to traveller - Subject: Booking cancelled`;
export const EDIT_BOOKING_SEND_EMAIL_TRAVELLER_UPDATED = `${FIELD} ${EDIT_BOOKING}: Send email to traveller - Subject: Booking updated`;
export const EDIT_BOOKING_SEND_EMAIL_TRAVELLER_CONFIRMED = `${FIELD} ${EDIT_BOOKING}: Send email to traveller - Subject: Booking confirmed`;
export const EDIT_BOOKING_SAVE = `${BUTTON} ${EDIT_BOOKING}: Save`;
export const EDIT_BOOKING_SAVE_SUCCESS = `${EDIT_BOOKING_SAVE}: Success`;
export const EDIT_BOOKING_SAVE_FAILED = `${EDIT_BOOKING_SAVE}: Failed`;
export const EDIT_BOOKING_BACK =  `${BUTTON} ${EDIT_BOOKING}: Back`;

export const ACCOUNTS = 'Accounts overview';
export const ACCOUNTS_OVERVIEW = `${SCREEN} Accounts overview`;
export const ACCOUNTS_HOTEL = `${TAB} ${ACCOUNTS}: Hotels`;
export const ACCOUNTS_CUSTOMER = `${TAB} ${ACCOUNTS}: Customers`;
export const CREATE_HOTEL_BUTTON = `${BUTTON} ${ACCOUNTS}: Hotels: Create`;
export const CREATE_CUSTOMER_BUTTON = `${BUTTON} ${ACCOUNTS}: Customer: Create`;

export const ACCOUNTS_CREATE_HOTEL = `${ACCOUNTS}: Create hotel`
export const ACCOUNTS_CREATE_CUSTOMER= `${ACCOUNTS}: Create customer`;
export const CREATE_HOTEL = `${SCREEN} ${ACCOUNTS_CREATE_HOTEL}`;
export const CREATE_CUSTOMER = `${SCREEN} ${ACCOUNTS}: Create customer`;

export const CREATE_HOTEL_ADDRESS = `${INPUT} ${ACCOUNTS_CREATE_HOTEL}: Address`;
export const CREATE_HOTEL_NAME = `${INPUT} ${ACCOUNTS_CREATE_HOTEL}: Name`;
export const CREATE_HOTEL_PHONE = `${INPUT} ${ACCOUNTS_CREATE_HOTEL}: Phone`;
export const CREATE_HOTEL_EMAIL = `${INPUT} ${ACCOUNTS_CREATE_HOTEL}: Email`;
export const CREATE_HOTEL_SAVE = `${BUTTON} ${ACCOUNTS_CREATE_HOTEL}: Create`;
export const CREATE_HOTEL_SAVE_SUCCESS = `${CREATE_HOTEL_SAVE}: Success`;
export const CREATE_HOTEL_SAVE_FAILED = `${CREATE_HOTEL_SAVE}: Failed`;
export const CREATE_HOTEL_CANCEL = `${BUTTON} ${ACCOUNTS_CREATE_HOTEL}: Cancel`;

export const CREATE_CUSTOMER_ADDRESS = `${INPUT} ${ACCOUNTS_CREATE_CUSTOMER}: Address`;
export const CREATE_CUSTOMER_NAME = `${INPUT} ${ACCOUNTS_CREATE_CUSTOMER}: Name`;
export const CREATE_CUSTOMER_SAVE = `${BUTTON} ${ACCOUNTS_CREATE_CUSTOMER}: Create`;
export const CREATE_CUSTOMER_SAVE_SUCCESS = `${CREATE_CUSTOMER_SAVE}: Success`;
export const CREATE_CUSTOMER_SAVE_FAILED = `${CREATE_CUSTOMER_SAVE}: Failed`;
export const CREATE_CUSTOMER_CANCEL = `${BUTTON} ${ACCOUNTS_CREATE_CUSTOMER}: Cancel`;


export const ACCOUNTS_EDIT_HOTEL = `${ACCOUNTS}: Edit hotel`
export const ACCOUNTS_EDIT_CUSTOMER= `${ACCOUNTS}: Edit customer`;
export const EDIT_HOTEL = `${SCREEN} ${ACCOUNTS_EDIT_HOTEL}`;
export const EDIT_CUSTOMER = `${SCREEN} ${ACCOUNTS}: Edit customer`;

export const EDIT_HOTEL_LISTITEM = `${LISTITEM} ${ACCOUNTS}: Hotels: Edit`;
export const EDIT_CUSTOMER_LISTITEM = `${LISTITEM} ${ACCOUNTS}: Customer: Edit`;

export const EDIT_HOTEL_ADDRESS = `${INPUT} ${ACCOUNTS_EDIT_HOTEL}: Address`;
export const EDIT_HOTEL_NAME = `${INPUT} ${ACCOUNTS_EDIT_HOTEL}: Name`;
export const EDIT_HOTEL_PHONE = `${INPUT} ${ACCOUNTS_EDIT_HOTEL}: Phone`;
export const EDIT_HOTEL_EMAIL = `${INPUT} ${ACCOUNTS_EDIT_HOTEL}: Email`;
export const EDIT_HOTEL_SAVE = `${BUTTON} ${ACCOUNTS_EDIT_HOTEL}: Save`;
export const EDIT_HOTEL_SAVE_SUCCESS = `${EDIT_HOTEL_SAVE}: Success`;
export const EDIT_HOTEL_SAVE_FAILED = `${EDIT_HOTEL_SAVE}: Failed`;
export const EDIT_HOTEL_CANCEL = `${BUTTON} ${ACCOUNTS_EDIT_HOTEL}: Cancel`;

export const EDIT_CUSTOMER_ADDRESS = `${INPUT} ${ACCOUNTS_EDIT_CUSTOMER}: Address`;
export const EDIT_CUSTOMER_NAME = `${INPUT} ${ACCOUNTS_EDIT_CUSTOMER}: Name`;
export const EDIT_CUSTOMER_SAVE = `${BUTTON} ${ACCOUNTS_EDIT_CUSTOMER}: Save`;
export const EDIT_CUSTOMER_SAVE_SUCCESS = `${EDIT_CUSTOMER_SAVE}: Success`;
export const EDIT_CUSTOMER_SAVE_FAILED = `${EDIT_CUSTOMER_SAVE}: Failed`;
export const EDIT_CUSTOMER_CANCEL = `${BUTTON} ${ACCOUNTS_EDIT_CUSTOMER}: Cancel`;
