export const ALL_CUSTOMERS = 'all-customers';
export const CUSTOMER = 'customer';
export const CUSTOMER_ROLES = 'customer-roles';

export const PEOPLE = 'people';
export const ACTIVE_PEOPLE = 'active-people';
export const INVITED_PEOPLE = 'invited-people';
export const DEACTIVATED_PEOPLE = 'deactivated-people';
export const REVOKE_INVITATION = 'revoke-invitation';

export const ALL_HOTELS = 'all-hotels';
export const ALL_HOTELS_NEARBY = 'all-hotels-nearby';
export const HOTEL = 'hotel';

export const ALL_ROOM_TYPES = 'all-room-types';
export const ALL_BENEFITS = 'all-benefits';
export const ALL_TRAVELLERS = 'all-travellers';

export const REQUESTED = 'all-requested';
export const INPROGRESS = 'all-in-progress';
export const UPCOMING = 'all-upcoming';
export const ARCHIVE = 'all-archive';

export const SEARCH_REQUESTS = 'search-requests';
export const REQUEST = 'request';
export const BOOKING = 'booking';

export const ACTIVE_HOTEL_USERS = 'active-hotel-users';
export const INVITED_HOTEL_USERS= 'invited-hotel-users';
export const DEACTIVATED_HOTEL_USERS = 'deactivated-hotel-users';