import {
    Box,
    Card,
    CardContent,
    Grid,
    Rating,
    Typography,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
    MessageDialog,
    PrimaryButton,
    SecondaryButton,
    Spinner,
} from '@get-e/react-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import LabeledData from '../../../components/LabeledData';
import GoogleMap, { Point } from '../../../components/GoogleMap';
import PageTitle from '../../../components/PageTitle';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import {
    getDaysMonthRangeString,
    getFormattedDateTimeString,
} from '../../../helpers/dateUtils';
import { TIME_FORMATS } from '../../../helpers/timeFormats';
import { useRequest } from '../api/useRequest';
import { Benefits, BookingStatus } from '../api/types';
import { DEFAULT_COORDINATES } from '../../../helpers/maps/googleApi';
import BookingCard from '../components/bookingCard/BookingCard';
import StatusButton from '../components/StatusButton';
import {
    CREATE_BOOKING,
    EDIT_REQUEST_BASE_URL,
    REQUESTS_PENDING,
} from '../../../constans/urlPaths';
import { CreateBookingLocationState } from '../index';
import { cancelRequest } from '../../../services/request';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { COLORS } from '../../../constans/colors';
import PermissionsGate from '../../../permissions/PermissionsGate';
import { SCOPES } from '../../../permissions/permissionMaps';
import { REQUEST_ID_PREFIX } from '../../../constans/prefixes';
import { RoomType } from '../../hotels/api/types';
import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import * as amplitudeLogs from '../../../constans/amplitudeKeys';

const useStyles = makeStyles({
    header: {
        display: 'flex',
    },
    map: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '220px',
        padding: '0 !important',
    },
    bookingStatus: {
        lineHeight: 'unset',
    },
    label: {
        fontSize: '1rem',
        marginRight: '1rem',
        fontWeight: '700',
    },
    buttonCancel: { marginLeft: '1rem' },
    addReservationButton: {
        width: '120px',
    },
    confirmCancelRequestButton: {
        background: COLORS.RED,
    },
    headerTitle: {
        marginBottom: '.5rem',
    },
});

const ViewRequest = () => {
    const classes = useStyles();
    const { id = '' } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();

    const { data, isFetching } = useRequest(parseInt(id, 10));

    const isEditableAndCancellable =
        data?.status !== BookingStatus['COMPLETED'] &&
        data?.status !== BookingStatus['BOOKING_CANCELLED'];

    useEffect(() => {
        if (!data) {
            return;
        }

        location.state = {
            id: data.id,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const { mutate: cancelRequestMutation } = useMutation(cancelRequest, {
        onSuccess: () => {
            showNotification(
                'Reservation request successfully cancelled.',
                Severity.Info,
            );
            setIsCancelModalOpen(false);
            navigate(REQUESTS_PENDING);
        },
        onError: (error: Error) => {
            setIsCancelModalOpen(false);
            showNotification(t('errors.retry'), Severity.Error);
        },
    });

    const title = useMemo(() => {
        return `${getDaysMonthRangeString(
            data?.check_in_date_local,
            data?.check_out_date_local,
        )} ${data?.request_address.short_name ? 'nearby' : ''} ${
            data?.request_address.short_name ?? ''
        }`;
    }, [
        data?.check_in_date_local,
        data?.check_out_date_local,
        data?.request_address.short_name,
    ]);

    const guest = useMemo(() => {
        return data?.guests[0];
    }, [data?.guests]);

    const roomTypes = useMemo(() => {
        if (!data?.room_types) {
            return '';
        }

        let result = '';
        data?.room_types.forEach((roomType: RoomType, index: number) => {
            if (index > 0) {
                result += ', ' + roomType.name;
            } else {
                result += roomType.name;
            }
        });

        return result;
    }, [data?.room_types]);

    const benefits = useMemo(() => {
        return data?.benefits?.map(benefit => benefit.name);
    }, [data?.benefits]);

    const includeBreakfast = useMemo(
        () =>
            benefits?.includes(Benefits.INCLUDE_BREAKFAST) ? 'Required' : 'Not required',
        [benefits],
    );

    const includeParking = useMemo(
        () =>
            benefits?.includes(Benefits.INCLUDE_PARKING) ? 'Required' : 'Not required',
        [benefits],
    );

    const points = useMemo(() => {
        return [
            {
                latitude: parseFloat(
                    data?.request_address.lat ?? DEFAULT_COORDINATES.lat.toString(),
                ),
                longitude: parseFloat(
                    data?.request_address.long ?? DEFAULT_COORDINATES.long.toString(),
                ),
            } as Point,
        ];
    }, [data?.request_address]);

    const driverPosition = useMemo(() => {
        return {
            timestamp: moment(),
            coordinates: {
                latitude: parseFloat(
                    data?.request_address.lat ?? DEFAULT_COORDINATES.lat.toString(),
                ),
                longitude: parseFloat(
                    data?.request_address.long ?? DEFAULT_COORDINATES.long.toString(),
                ),
            },
            coordinatesAccuracyMeters: 100,
            bearingDegrees: 0,
        };
    }, [data?.request_address]);

    const handleEdit = () => {
        navigate(`${EDIT_REQUEST_BASE_URL}${id}`, {
            state: {
                requestNumber: data?.id,
            },
        });
        logAmplitudeEvent(amplitudeLogs.REQUEST_EDIT);
    };

    const handleCancel = () => {
        setIsCancelModalOpen(true);
        logAmplitudeEvent(amplitudeLogs.REQUEST_CANCEL);
    };

    const handleAddBooking = () => {
        navigate(`${CREATE_BOOKING}`, {
            state: {
                request: data,
            } as CreateBookingLocationState,
        });
        logAmplitudeEvent(amplitudeLogs.BOOKING_ADD);
    };

    const handleCloseCancelModal = () => {
        setIsCancelModalOpen(false);
        logAmplitudeEvent(amplitudeLogs.REQUEST_CANCEL_CLOSE);
    };

    const handleConfirmCancelModal = () => {
        cancelRequestMutation(parseInt(id));
        logAmplitudeEvent(amplitudeLogs.REQUEST_CANCEL_CONFIRM);
    };

    return (
        <>
            <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ marginBottom: '1.5rem' }}
            >
                <Grid item xs={12} md={8} xl={8}>
                    <PageTitle title={title} className={classes.headerTitle} />
                    <Grid container sx={{ marginBottom: '0.75rem' }}>
                        <Grid item xs={1} alignSelf="center" sx={{ marginRight: '1rem' }}>
                            <Typography
                                sx={{
                                    color: COLORS.DARK_GRAY,
                                }}
                            >
                                {REQUEST_ID_PREFIX}
                                {data?.id}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} textAlign="left">
                            {data?.status && (
                                <StatusButton
                                    className={classes.bookingStatus}
                                    variant={data?.status}
                                >
                                    {data?.status}
                                </StatusButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {isEditableAndCancellable && (
                    <Grid
                        item
                        xs={12}
                        md={4}
                        xl={4}
                        textAlign={isMobile ? 'left' : 'right'}
                        sx={{ paddingTop: '0 !important' }}
                    >
                        <PermissionsGate scopes={[SCOPES.canEditRequest]}>
                            <SecondaryButton onClick={handleEdit}>Edit</SecondaryButton>
                        </PermissionsGate>
                        <PermissionsGate scopes={[SCOPES.canCancelRequest]}>
                            <SecondaryButton
                                onClick={handleCancel}
                                className={classes.buttonCancel}
                                variation="danger"
                            >
                                Cancel
                            </SecondaryButton>
                        </PermissionsGate>
                    </Grid>
                )}
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} xl={6}>
                        <Card variant="outlined">
                            <CardContent className={classes.map}>
                                {isFetching && <Spinner size={50} />}
                                {!isFetching && data && points && (
                                    <GoogleMap
                                        points={points}
                                        driverPosition={driverPosition}
                                    />
                                )}
                            </CardContent>
                        </Card>
                        <Card variant="outlined" sx={{ marginTop: '2rem' }}>
                            <CardContent>
                                <LabeledData
                                    label="Traveller"
                                    labelColor={COLORS.BLUE}
                                    labelColumnNumber={12}
                                />
                                <LabeledData
                                    label="Name"
                                    data={`${guest?.first_name ?? ''} ${
                                        guest?.last_name ?? ''
                                    }`}
                                    dataSuffix={
                                        guest?.traveller?.employee_number.toString() ?? ''
                                    }
                                />
                                <LabeledData
                                    label="Phone number"
                                    data={`${guest?.phone_number ?? ''}`}
                                />
                                <LabeledData
                                    label="Email"
                                    data={`${guest?.email ?? ''}`}
                                />
                            </CardContent>
                        </Card>
                        <Card variant="outlined" sx={{ marginTop: '2rem' }}>
                            <CardContent>
                                <LabeledData
                                    label="Nearby"
                                    data={data?.request_address.short_name}
                                />
                                <LabeledData
                                    label="Check in"
                                    data={getFormattedDateTimeString(
                                        data?.check_in_date_local ?? '',
                                        DATE_FORMATS['DD MMM YYYY'],
                                        TIME_FORMATS['HH:mm A'],
                                        data?.is_time_included,
                                    )}
                                />
                                <LabeledData
                                    label="Check out"
                                    data={getFormattedDateTimeString(
                                        data?.check_out_date_local ?? '',
                                        DATE_FORMATS['DD MMM YYYY'],
                                        TIME_FORMATS['HH:mm A'],
                                        data?.is_time_included,
                                    )}
                                />
                                <LabeledData
                                    label="Special request"
                                    data={isFetching ? '' : data?.comments || ''}
                                />
                            </CardContent>
                        </Card>
                        <Card variant="outlined" sx={{ marginTop: '2rem' }}>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ marginBottom: '0.5rem' }}
                                >
                                    <Grid item xs={4}>
                                        <Typography
                                            className={classes.label}
                                            variant="caption"
                                            gutterBottom
                                            sx={{ color: COLORS.BLUE }}
                                        >
                                            Star-rating
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                        sx={{ paddingLeft: '14px !important' }}
                                    >
                                        <Rating
                                            value={data?.minimum_star_rating ?? 0}
                                            readOnly
                                        />
                                    </Grid>
                                </Grid>
                                <LabeledData label="Room type" data={roomTypes} />
                                <LabeledData
                                    label="Breakfast"
                                    data={isFetching ? '' : includeBreakfast}
                                />
                                <LabeledData
                                    label="Parking"
                                    data={isFetching ? '' : includeParking}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    {isFetching && (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            xl={6}
                            sx={{
                                alignItems: 'start',
                                justifyContent: 'center',
                                display: 'flex',
                                paddingTop: '4rem !important',
                            }}
                        >
                            <Spinner size={50} />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} xl={6}>
                        {data?.bookings.map(reservation => {
                            return (
                                <BookingCard
                                    reservation={reservation}
                                    key={reservation.id}
                                />
                            );
                        })}
                        <PermissionsGate>
                            <PrimaryButton
                                onClick={handleAddBooking}
                                className={classes.addReservationButton}
                            >
                                Add
                            </PrimaryButton>
                        </PermissionsGate>
                    </Grid>
                </Grid>
            </Box>
            {isCancelModalOpen && (
                <MessageDialog
                    onClose={handleCloseCancelModal}
                    onConfirm={handleConfirmCancelModal}
                    title="Cancel request"
                    isConfirmButtonDisplayed
                    closeButtonLabel="Back"
                    confirmButtonLabel="Confirm"
                    confirmButtonClassName={classes.confirmCancelRequestButton}
                    closeButtonType="secondary"
                >
                    <Typography sx={{ padding: '1rem 0' }}>
                        <strong>This operation can't be reversed.</strong> Are you sure
                        you want to proceed?
                    </Typography>
                </MessageDialog>
            )}
        </>
    );
};

export default ViewRequest;
