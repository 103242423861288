import {
    Card,
    CardContent,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
    SecondaryButton,
    Spinner,
} from '@get-e/react-components';

import LabeledData from '../../../components/LabeledData';
import GoogleMap, { Point } from '../../../components/GoogleMap';
import PageTitle from '../../../components/PageTitle';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import { getFormattedDateTimeString } from '../../../helpers/dateUtils';
import { TIME_FORMATS } from '../../../helpers/timeFormats';
import { Benefits, BookingCustomField } from '../api/types';
import { DEFAULT_COORDINATES } from '../../../helpers/maps/googleApi';
import StatusButton from '../components/StatusButton';
import {
    EDIT_BOOKING_BASE_URL,
} from '../../../constans/urlPaths';
import { COLORS } from '../../../constans/colors';
import PermissionsGate from '../../../permissions/PermissionsGate';
import { SCOPES } from '../../../permissions/permissionMaps';
import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import * as amplitudeLogs from '../../../constans/amplitudeKeys';
import {useBooking} from "../api/useBooking";
import LocalParkingOutlinedIcon from "@mui/icons-material/LocalParkingOutlined";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";
import { calculateBookingPrice } from '../../../helpers/finance';
import { useIsBackoffice } from '../../../context/AuthenticatedUserContext';
import TransactionTable from './finance/TransactionTable';
import { SyncLockSharp, SyncOutlined, TitleOutlined } from '@mui/icons-material';
import ChangeLogTable from './changeLogs/ChangeLogTable';

const useStyles = makeStyles({
    header: {
        display: 'flex',
    },
    map: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 !important',
    },
    bookingStatus: {
        lineHeight: 'unset',
    },
    label: {
        fontSize: '1rem',
        marginRight: '1rem',
        fontWeight: '700',
    },
    buttonCancel: { marginLeft: '1rem' },
    addReservationButton: {
        width: '120px',
    },
    confirmCancelRequestButton: {
        background: COLORS.RED,
    },
    headerTitle: {
        margin: '1rem 0rem',
    },
    reservationStatus: {
        lineHeight: 'unset',
    },
    editButton: {
        width: '120px',
    },
});

const ViewBooking = () => {
    const classes = useStyles();
    const { id = '' } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { data, isFetching } = useBooking(parseInt(id, 10));

    useEffect(() => {
        if (!data) {
            return;
        }

        location.state = {
            id: data.id,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const guest = useMemo(() => {
        return data?.bookingGuests[0];
    }, [data?.bookingGuests]);

    const benefits = useMemo(() => {
        return data?.benefits?.map(benefit => benefit.name);
    }, [data?.benefits]);

    const includeBreakfast = useMemo(
        () =>
            benefits?.includes(Benefits.INCLUDE_BREAKFAST) ? 'Breakfast Required' : 'Breakfast Not required',
        [benefits],
    );

    const includeParking = useMemo(
        () =>
            benefits?.includes(Benefits.INCLUDE_PARKING) ? 'Parking Required' : 'Parking Not required',
        [benefits],
    );

    const points = useMemo(() => {
        return [
            {
                latitude: parseFloat(
                    data?.hotelLatitude ?? DEFAULT_COORDINATES.lat.toString(),
                ),
                longitude: parseFloat(
                    data?.hotelLongitude ?? DEFAULT_COORDINATES.long.toString(),
                ),
            } as Point,
        ];
    }, [data]);

    const handleEdit = () => {
        navigate(`${EDIT_BOOKING_BASE_URL}${id}`, {
            state: {
                requestNumber: data?.id,
            },
        });
        logAmplitudeEvent(amplitudeLogs.BOOKING_EDIT);
    };

    const isBackoffice = useIsBackoffice();

    return (
        <>
            <Grid container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  alignItems="center"
            >
                {data?.customFields.find((field: BookingCustomField) => field.key == "is-training-booking" && field.value == "1" ) &&
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                            <Grid item xs={12}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                    <Grid item xs={2} sx={{ paddingTop: '0' }}>
                                        <TitleOutlined fontSize="small" sx={{display: "inline"}}></TitleOutlined>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}
                                    >
                                        <Typography
                                            sx={{ fontSize: '1rem', display: "inline"}}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Training Booking
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {data?.isAutomated &&
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                            <Grid item xs={12}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                    <Grid item xs={2} sx={{ paddingTop: '0' }}>
                                    <SyncOutlined fontSize="small" sx={{display: "inline"}}></SyncOutlined>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}
                                    >
                                                    <Typography
                                    sx={{ fontSize: '1rem', display: "inline"}}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    Automated Booking
                                </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
         
                {data?.isIgnoredBySynchronizer &&
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                            <Grid item xs={12}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                    <Grid item xs={2} sx={{ paddingTop: '0' }}>
                                    <SyncLockSharp fontSize="small" sx={{display: "inline"}}></SyncLockSharp>    
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}
                                    >
                                        <Typography
                                            sx={{ fontSize: '1rem', display: "inline"}}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Ignored by Synchronizer
                                        </Typography>   
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>

            {/* Status | Hotel Name | Edit Button*/}
            <Grid container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  alignItems="center"
                  sx={{ marginBottom: '1.5rem' }}
            >
                <Grid item xs={isMobile ? 4 : 2}>
                    {data?.status && (
                        <StatusButton
                            className={classes.bookingStatus}
                            variant={data?.status}
                        >
                            {data?.status}
                        </StatusButton>
                    )}
                </Grid>

                {!isMobile &&
                    <Grid item xs={isMobile ? 8 : 7}>
                        <PageTitle title={data?.hotelName} className={classes.headerTitle} />
                    </Grid>
                }

                <Grid item xs={isMobile ? 8 : 3}
                      textAlign={'right'}
                >
                    <PermissionsGate scopes={[SCOPES.canEditBooking]}>
                        <SecondaryButton
                            onClick={handleEdit}
                            className={classes.editButton}
                        >Edit</SecondaryButton>
                    </PermissionsGate>
                </Grid>
            </Grid>

            {/* Map and Address */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Card variant="outlined">
                        {isFetching && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Spinner size={50} />
                            </Grid>
                        )}
                        {!isFetching &&
                            <CardContent
                                sx={{
                                    paddingBottom: '0.5rem!important',
                                    paddingTop: '1rem!important'
                                }}
                            >
                                <LabeledData
                                    label="Hotel Name"
                                    data={data?.hotelName}
                                />
                                <LabeledData
                                    label="Address"
                                    data={data?.hotelAddressLine}
                                />

                                <LabeledData
                                    label="Country"
                                    data={data?.hotelCountryName}
                                />
                                <LabeledData
                                    label="Check-in"
                                    data={moment(data?.hotelStandardCheckIn,'HH:mm:ss').format("HH:mm")}
                                />
                                <LabeledData
                                    label="Check-out"
                                    data={moment(data?.hotelStandardCheckOut,'HH:mm:ss').format("HH:mm")}
                                />
                            </CardContent>
                        }
                    </Card>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Card variant="outlined"
                        sx={{
                            height: `${isMobile ? '180px' : '100%'}`,
                        }}
                    >
                        <CardContent className={classes.map}
                             sx={{
                                 height:'100%',
                             }}
                        >
                            {isFetching && <Spinner size={50} />}
                            {!isFetching && data && points && (
                                <GoogleMap points={points} />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Guest */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ marginTop: '1rem' }}
            >
                <Grid item xs={12}>
                    <LabeledData
                        label="Guest"
                        labelColor={COLORS.BLUE}
                        labelColumnNumber={12}
                    />
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        {isFetching && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Spinner size={30} />
                            </Grid>
                        )}
                        {!isFetching &&
                            <CardContent
                                sx={{paddingBottom: '0.5rem !important'}}
                            >
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                    <Grid item xs={isMobile? 12 : 4}>
                                        <LabeledData
                                            label="Name"
                                            data={`${guest?.first_name ?? ''} ${
                                                guest?.last_name ?? ''
                                            }`}
                                            dataSuffix={
                                                guest?.traveller?.employee_number.toString() ?? ''
                                            }
                                            labelColumnNumber={isMobile? 4 : 3.5}
                                            dataColumnNumber={isMobile? 8 : 8.5}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobile? 12 : 4}>
                                        <LabeledData
                                            label="Phone"
                                            data={`${guest?.phone_number ?? ''}`}
                                            labelColumnNumber={4}
                                            dataColumnNumber={8}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobile? 12 : 4}>
                                        <LabeledData
                                            label="Email"
                                            data={`${guest?.email ?? ''}`}
                                            labelColumnNumber={isMobile? 4 : 2.5}
                                            dataColumnNumber={isMobile? 8 : 9.5}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        }
                    </Card>
                </Grid>
            </Grid>

            {/* Check In/Out */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginTop: '1rem' }}
            >
                <Grid item xs={12}>
                    <LabeledData
                        label="Booking Details"
                        labelColor={COLORS.BLUE}
                        labelColumnNumber={12}
                    />
                </Grid>
            </Grid>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        {isFetching && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Spinner size={30} />
                            </Grid>
                        )}
                        {!isFetching &&
                            <CardContent
                                sx={{paddingBottom: '0.5rem !important'}}
                            >
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                    <Grid item xs={isMobile? 12 : (!isBackoffice ? 4 : 6)}>
                                        <LabeledData
                                            label="Check-in"
                                            data={getFormattedDateTimeString(
                                                data?.checkInDateLocal ?? '',
                                                DATE_FORMATS['DD MMM YYYY'],
                                                TIME_FORMATS['HH:mm'],
                                                data?.isTimeIncluded,
                                            )}
                                            labelColumnNumber={isMobile? 4 : 4}
                                            dataColumnNumber={isMobile? 8 : 8}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobile? 12 : (!isBackoffice ? 4 : 6)}>
                                        <LabeledData
                                            label="Check-out"
                                            data={getFormattedDateTimeString(
                                                data?.checkOutDateLocal ?? '',
                                                DATE_FORMATS['DD MMM YYYY'],
                                                TIME_FORMATS['HH:mm'],
                                                data?.isTimeIncluded,
                                            )}
                                            labelColumnNumber={isMobile? 4 : 5}
                                            dataColumnNumber={isMobile? 8 : 7}
                                        />
                                    </Grid>
                                    {!isBackoffice && 
                                        <Grid item xs={isMobile? 12 : 4}>
                                        <LabeledData
                                            label="Price"
                                            data={calculateBookingPrice(data?.financeTransactions) || ''}
                                            labelColumnNumber={isMobile ? 8 : 2.5}
                                            dataColumnNumber={isMobile ? 4 : 9.5}
                                        />
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        }
                    </Card>
                </Grid>
            </Grid>

            {/* Confirmation Details */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginTop: '1rem' }}
            >
                <Grid item xs={12}>
                    <LabeledData
                        label="Confirmation Details"
                        labelColor={COLORS.BLUE}
                        labelColumnNumber={12}
                    />
                </Grid>
            </Grid>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        {isFetching && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Spinner size={30} />
                            </Grid>
                        )}
                        {!isFetching &&
                            <CardContent
                                sx={{paddingBottom: '0.5rem !important'}}
                            >
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                    <Grid item xs={isMobile? 12 : 6}>
                                        <LabeledData
                                            label="Confirmed at"
                                            data={getFormattedDateTimeString(
                                                data?.confirmationTimeLocal ?? '',
                                                DATE_FORMATS['DD MMM YYYY'],
                                                TIME_FORMATS['HH:mm A'],
                                                true,
                                            )}
                                            labelColumnNumber={isMobile ? 5 : 4}
                                            dataColumnNumber={isMobile ? 7 : 8}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobile? 12 : 6}>
                                        <LabeledData
                                            label="Confirmation Number"
                                            data={data?.bookingNumber}
                                            labelColumnNumber={isMobile ? 8 : 5}
                                            dataColumnNumber={isMobile ? 4 : 7}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        }
                    </Card>
                </Grid>
            </Grid>

            {/* Special Request */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginTop: '1rem' }}
            >
                <Grid item xs={12}>
                    <LabeledData
                        label="Special Request"
                        labelColor={COLORS.BLUE}
                        labelColumnNumber={12}
                    />
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        {isFetching && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Spinner size={30} />
                            </Grid>
                        )}
                        {!isFetching &&
                            <CardContent
                                sx={{paddingBottom: '1rem !important'}}
                            >
                                <Typography>
                                    {isFetching ? '' : data?.notes || ''}
                                </Typography>
                            </CardContent>
                        }
                    </Card>
                </Grid>
            </Grid>

            {/* Hotel Comments */}
            {isBackoffice && <div>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ marginTop: '1rem' }}
                >
                    <Grid item xs={12}>
                        <LabeledData
                            label="Hotel Comments"
                            labelColor={COLORS.BLUE}
                            labelColumnNumber={12}
                        />
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            {isFetching && (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <Spinner size={30} />
                                </Grid>
                            )}
                            {!isFetching &&
                                <CardContent
                                    sx={{paddingBottom: '1rem !important'}}
                                >
                                    <Typography>
                                        {isFetching ? '' : data?.hotelComments || ''}
                                    </Typography>
                                </CardContent>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </div>}

            {/* Benefits */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginTop: '1rem' }}

            >
                <Grid item xs={12}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                        <Grid item xs={1} sx={{ paddingTop: '0' }}>
                            <LocalParkingOutlinedIcon fontSize="small" />
                        </Grid>
                        <Grid
                            item
                            xs={5}
                            sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}
                        >
                            <Typography
                                sx={{ fontSize: '1rem' }}
                                color="text.secondary"
                                gutterBottom
                            >
                                {includeParking}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ paddingTop: '0' }}>
                            <LocalCafeOutlinedIcon fontSize="small" />
                        </Grid>
                        <Grid
                            item
                            xs={5}
                            sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}
                        >
                            <Typography
                                sx={{ fontSize: '1rem' }}
                                color="text.secondary"
                                gutterBottom
                            >
                                {includeBreakfast}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            {/* Finance */}  
            {isBackoffice && <div>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ marginTop: '1rem' }}
                >
                    <Grid item xs={12}>
                        <LabeledData
                            label="Finance"
                            labelColor={COLORS.BLUE}
                            labelColumnNumber={12}
                        />
                    </Grid>
                </Grid>
                <TransactionTable transactions={data?.financeTransactions}></TransactionTable>
            </div>}

            {/* Change Logs */}  
            {isBackoffice && data?.changeLogs && <div>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ marginTop: '1rem' }}
                >
                    <Grid item xs={12}>
                        <LabeledData
                            label="Change Logs"
                            labelColor={COLORS.BLUE}
                            labelColumnNumber={12}
                        />
                    </Grid>
                </Grid>
                <ChangeLogTable changeLogs={data?.changeLogs}></ChangeLogTable>
            </div>}
        </>
    );
};

export default ViewBooking;
