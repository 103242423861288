import { MuiTelInput } from 'mui-tel-input';
import { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-query';
import {
    Modal,
    PrimaryButton,
    TextField,
    SecondaryButton,
} from '@get-e/react-components';
import { makeStyles } from '@mui/styles';
import { CardContent, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import isEmail from '../../../helpers/validation/validators/isEmail';
import InputError from '../../../helpers/validation/InputError';
import { createTraveller } from '../../../services/traveller';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import allValid from '../../../helpers/validation/allValid';
import getHelperText from '../../../helpers/validation/getHelperText';
import PageTitle from '../../../components/PageTitle';
import isPhoneNumber from '../../../helpers/validation/validators/isPhoneNumber';
import { getCurrentUserCountryCode } from '../../../helpers/getCurrentUserCountryCode';
import { AddTravellerResponse } from '../api/types';import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import * as amplitudeLogs from '../../../constans/amplitudeKeys';
import { AxiosError } from 'axios';

const useStyles = makeStyles({
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    firstName: {
        marginRight: '1rem',
    },
    lastName: {
        marginLeft: '1rem',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1rem',
    },
    buttonCancel: {
        marginLeft: '2em',
    },
    formField: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
});

interface AddNewTravellerModalProps {
    onClose: () => void;
    isOpen: boolean;
    onTravellerCreated: (traveller: AddTravellerResponse) => void;
    customerId?: number;
}

const AddNewTravellerModal: FunctionComponent<AddNewTravellerModalProps> = ({
    onClose,
    isOpen,
    onTravellerCreated,
    customerId,
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const classes = useStyles();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [employeeNumber, setEmployeeNumber] = useState('');

    const [firstNameError, setFirstNameError] = useState<InputError | null>(null);
    const [lastNameError, setLastNameError] = useState<InputError | null>(null);
    const [employeeNumberError, setEmployeeNumberError] = useState<InputError | null>(
        null,
    );
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const [mobileError, setMobileError] = useState<InputError | null>(null);

    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();
    const defaultCountryCode = getCurrentUserCountryCode();

    const { mutate: addTravellerMutation, isLoading } = useMutation(createTraveller, {
        onSuccess: data => {
            logAmplitudeEvent(amplitudeLogs.ADD_NEW_TRAVELLER_ADD_SUCCESS);

            showNotification('Traveller successfully added.', Severity.Info);
            onTravellerCreated(data.data);
        },
        onError: (error: AxiosError<Error>) => {
            logAmplitudeEvent(amplitudeLogs.ADD_NEW_TRAVELLER_ADD_FAILED);
            showNotification( error.response?.data.message ?? t('errors.retry'), Severity.Error);
            onClose();
        },
    });

    const validateFormFields = (): boolean => {
        const validated = {
            firstName: isFilledString(firstName, InputError.Required),
            lastName: isFilledString(lastName, InputError.Required),
            employeeNumber: isFilledString(employeeNumber, InputError.Required),
            email: isEmail(email, InputError.InvalidEmail),
            mobile: isPhoneNumber(mobile, InputError.InvalidPhoneNumber),
        };

        if (!allValid(validated)) {
            setFirstNameError(
                validated.firstName.isValid ? null : validated.firstName.error,
            );

            setLastNameError(
                validated.lastName.isValid ? null : validated.lastName.error,
            );
            setEmployeeNumberError(
                validated.employeeNumber.isValid ? null : validated.employeeNumber.error,
            );
            setEmailError(validated.email.isValid ? null : validated.email.error);

            setMobileError(validated.mobile.isValid ? null : validated.mobile.error);

            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        logAmplitudeEvent(amplitudeLogs.ADD_NEW_TRAVELLER_ADD);
        if (validateFormFields()) {
            addTravellerMutation({
                firstName,
                lastName,
                email,
                mobile: mobile?.replace(/\s/g, ''),
                employeeNumber,
                customerId: customerId,
            });
        }
    };

    const handleCancel = () => {
        onClose();
        logAmplitudeEvent(amplitudeLogs.ADD_NEW_TRAVELLER_CANCEL);
    };

    return (
        <Modal open={isOpen} onClose={onClose} fullScreen={!isMobile}>
            <CardContent
                sx={{
                    width: isMobile ? '100%' : '600px',
                    margin: '0 auto',
                    paddingLeft: '0',
                }}
            >
                <PageTitle title="Add new traveller" />
                <div className={classes.nameContainer}>
                    <TextField
                        value={firstName}
                        onChange={event => {
                            setFirstName(event.target.value);
                            setFirstNameError(null);
                        }}
                        label="First name"
                        type="text"
                        name="firstName"
                        error={firstNameError !== null}
                        helperText={getHelperText(firstNameError, t)}
                        className={classes.firstName}
                        required
                        onFocus={() => logAmplitudeEvent(amplitudeLogs.ADD_NEW_TRAVELLER_FIRST_NAME)}
                    />
                    <TextField
                        value={lastName}
                        onChange={event => {
                            setLastName(event.target.value);
                            setLastNameError(null);
                        }}
                        label="Last name"
                        type="text"
                        name="lastName"
                        error={lastNameError !== null}
                        helperText={getHelperText(lastNameError, t)}
                        className={classes.lastName}
                        required
                        onFocus={() => logAmplitudeEvent(amplitudeLogs.ADD_NEW_TRAVELLER_LAST_NAME)}
                    />
                </div>
                <TextField
                    value={email}
                    onChange={event => {
                        setEmail(event.target.value);
                        setEmailError(null);
                    }}
                    label="Email"
                    type="text"
                    autoComplete="email"
                    name="email"
                    error={emailError !== null}
                    helperText={getHelperText(emailError, t)}
                    margin="normal"
                    required
                    onFocus={() => logAmplitudeEvent(amplitudeLogs.ADD_NEW_TRAVELLER_EMAIL)}
                />
                <MuiTelInput
                    defaultCountry={defaultCountryCode}
                    label="Mobile"
                    value={mobile}
                    onChange={value => {
                        setMobile(value);
                        setMobileError(null);
                    }}
                    name="mobile"
                    error={mobileError !== null}
                    helperText={getHelperText(mobileError, t)}
                    className={classes.formField}
                    fullWidth
                    variant="filled"
                    required
                    onFocus={() => logAmplitudeEvent(amplitudeLogs.ADD_NEW_TRAVELLER_MOBILE)}
                />
                <TextField
                    value={employeeNumber}
                    onChange={event => {
                        setEmployeeNumber(event.target.value);
                        setEmployeeNumberError(null);
                    }}
                    label="Employee number"
                    type="text"
                    name="employeeNumber"
                    required
                    error={employeeNumberError !== null}
                    helperText={getHelperText(employeeNumberError, t)}
                    onFocus={() => logAmplitudeEvent(amplitudeLogs.ADD_NEW_TRAVELLER_EMPLOYEE)}
                />
                <div className={classes.buttonsContainer}>
                    <PrimaryButton onClick={handleSubmit} submitsForm loading={isLoading}>
                        Add
                    </PrimaryButton>
                    <SecondaryButton
                        onClick={handleCancel}
                        className={classes.buttonCancel}
                    >
                        Cancel
                    </SecondaryButton>
                </div>
            </CardContent>
        </Modal>
    );
};

export default AddNewTravellerModal;
