export const HOME = '/';
export const SIGN_IN = '/sign-in';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const ACCEPT_INVITE = '/accept-invite';
export const ACCOUNTS = '/accounts';
export const ACCOUNTS_CUSTOMERS = `${ACCOUNTS}/customers`;
export const ACCOUNTS_HOTELS = `${ACCOUNTS}/hotels`;
export const CREATE_ACCOUNT = '/create-account';
export const CREATE_CUSTOMER = `${ACCOUNTS}/create-customer`;
export const CREATE_HOTEL = `${ACCOUNTS}/create-hotel`;
export const EDIT_CUSTOMER_BASE_URL = `${ACCOUNTS}/edit-account/`;
export const EDIT_HOTEL_BASE_URL = `${ACCOUNTS}/edit-hotel/`;
export const EDIT_CUSTOMER = `${EDIT_CUSTOMER_BASE_URL}:id`;
export const EDIT_HOTEL = `${EDIT_HOTEL_BASE_URL}:id`;
export const USER = '/user/';
export const PEOPLE = '/people';
export const PEOPLE_ACTIVE = `${PEOPLE}/active`;
export const PEOPLE_INVITED = `${PEOPLE}/invited`;
export const PEOPLE_DEACTIVATED = `${PEOPLE}/deactivated`;
export const EDIT_PEOPLE_BASE_URL = `${PEOPLE}/edit-people/`;
export const EDIT_PEOPLE = `${EDIT_PEOPLE_BASE_URL}:id`;
export const ACCOUNT_PEOPLE_ACTIVE = `${PEOPLE_ACTIVE}/:id`;
export const ACCOUNT_PEOPLE_INVITED = `${PEOPLE_INVITED}/:id`;
export const ACCOUNT_PEOPLE_DEACTIVATED = `${PEOPLE_DEACTIVATED}/:id`;

export const REQUESTS = '/requests/';
export const REQUESTS_PENDING = `${REQUESTS}pending`;
export const REQUESTS_UPCOMING = `${REQUESTS}upcoming`;
export const REQUESTS_IN_PROGRESS = `${REQUESTS}in-progress`;
export const REQUESTS_ARCHIVE = `${REQUESTS}archive`;

export const VIEW_REQUESTS = `${REQUESTS}:id`;
export const CREATE_REQUEST = `${REQUESTS}create`;
export const EDIT_REQUEST_BASE_URL = `${REQUESTS}edit-request/`;
export const EDIT_REQUEST = `${EDIT_REQUEST_BASE_URL}:id`;

export const BOOKINGS = '/bookings';
export const CREATE_BOOKING = `${BOOKINGS}/create-booking`;
export const EDIT_BOOKING_BASE_URL = `${BOOKINGS}/edit-booking/`;
export const EDIT_BOOKING = `${EDIT_BOOKING_BASE_URL}:id`;
export const VIEW_BOOKINGS = `${BOOKINGS}/:id`;
export const BOOKINGS_PENDING = `${BOOKINGS}/pending`;
export const BOOKINGS_UPCOMING = `${BOOKINGS}/upcoming`;
export const BOOKINGS_IN_PROGRESS = `${BOOKINGS}/in-progress`;
export const BOOKINGS_ARCHIVE = `${BOOKINGS}/archive`;
export const ERROR_TOO_MANY_REQUESTS = '/too-many-requests';
export const ERROR_NOT_AUTHORIZED = '/not-authorized';
export const TRANSFER = 'https://portal.get-e.com/portal/login';

export const HOTEL_USERS_ACTIVE = `/hotel/users/active`;
export const HOTEL_USERS_INVITED = `/hotel/users/invited`;
export const HOTEL_USERS_DEACTIVATED = `/hotel/users/deactivated`;

export const EDIT_HOTEL_USER = `/hotel/users/edit/`;
