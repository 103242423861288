import { logAmplitudeEvent } from '../amplitude/amplitude';
import * as amplitudeLogs from '../constans/amplitudeKeys';

const mapTabToEvent: any = {
    Hotels: amplitudeLogs.ACCOUNTS_HOTEL,
    Customers: amplitudeLogs.ACCOUNTS_CUSTOMER,
    Active: amplitudeLogs.USERS_ACTIVE,
    Deactivated: amplitudeLogs.USERS_DEACTIVATED,
    Invited: amplitudeLogs.USERS_INVITED,
    Pending: amplitudeLogs.LOG_REQUESTS_PENDING,
    Archive: amplitudeLogs.LOG_REQUESTS_ARCHIVE,
    InProgress: amplitudeLogs.LOG_REQUESTS_INPROGRESS,
    Upcoming: amplitudeLogs.LOG_REQUESTS_UPCOMING,
};

export const logAmplitudeTab = (tab: number, tabs: any) => {
    const tabsValues = Object.values(tabs).filter(v => !isNaN(Number(v)));

    tabsValues.forEach((key: any) => {
        if (tab === key) return logAmplitudeEvent(mapTabToEvent[tabs[tab]]);
    });
};
