/* eslint-disable react-hooks/rules-of-hooks */
import { GridColDef } from '@mui/x-data-grid';

import { useDataGridStyles } from './styles/useDataGridStyles';

export const columns = (): GridColDef[] => {
    const classes = useDataGridStyles();

    return [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 200,
        },
        {
            field: 'name',
            headerName: 'Account name',
            minWidth: 300,
        },
        {
            field: 'address',
            headerName: 'Address',
            headerClassName: classes.hideRightSeparator,
            minWidth: 350,
            flex: 1,
        },
    ];
};

export const columnsHotel = (): GridColDef[] => {
    const classes = useDataGridStyles();
    
    return [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 100,
        },
        {
            field: 'name',
            headerName: 'Hotel name',
            minWidth: 200,
        },
        {
            field: 'hotelAddress',
            headerName: 'Address',
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 200,
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone',
            headerClassName: classes.hideRightSeparator,
            minWidth: 200,
        },
    ];
};
